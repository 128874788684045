/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Space, Table, Tooltip, Typography, message } from "antd";
import {   useState } from "react";
import { useSelector } from "react-redux";

import { PlusOutlined, QuestionOutlined } from "@ant-design/icons";
import { formatNumber } from "../../util";
import ProductInsumoForm from "./ProductInsumoForm";
import { getInsumos } from "../../data/api";

const ProductInsumo = ({ rowTable, insumoTable,  loading, setInsumoTable, setLoading }) => {
  const [initialValues, setInitialValues] = useState({});
  const [title, setTitle] = useState(false);
  const [visible, setVisible] = useState(false);
  
  const valResponsive = useSelector(state => state.responsive);
 
  



  const geProductInsumos = async () => {
   setLoading(true);
    try {
      const response = await getInsumos(rowTable.id);
      if (response.data.code === 0 && response.data.counts > 0) {
        const formattedData = response.data.results.map((item, index) => ({
          ...item,
          key: index,
        }));
        setInsumoTable(formattedData);
        
      } else {
        setInsumoTable([]);
      }

    } catch (error) {
      message.error(error.message);
    }
    setLoading(false);
  }

  

  const addInsumo = () => {

    setVisible(true);
    setTitle(rowTable.titulo);
    setInitialValues({ producto: rowTable.id });
  }
  const closeModal = () => {
    setVisible(false);
  }
  /*function handleView(foto){
      console.log('foto',foto);
  }*/


  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      mobile: false,
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
    },
    {
      title: 'Insumo',
      dataIndex: 'nombre',
      key: 'nombre',
      mobile: true,
    },
    {
      title: 'Costo',
      dataIndex: 'costo',
      key: 'costo',
      mobile: true,
      render: (text, record) => (
        <span>{formatNumber(record.costo)}</span>
      )
    },
    {
      title: 'Orden',
      dataIndex: 'orden',
      key: 'orden',
      mobile: false,
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
    },
  ];
  const filteredMenuItems = columns.filter(item => {
    if (valResponsive) {
      if (item.mobile) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }

  });



  const [lastClickTime, setLastClickTime] = useState(0);

  const handleClick = (record) => {
    
    setInitialValues(record);
    const clickTime = new Date().getTime();
    const doubleClickDelay = 300;

    if (clickTime - lastClickTime < doubleClickDelay) {


      setVisible(true);
      setTitle(rowTable.titulo);
    }

    setLastClickTime(clickTime);
  };

 
  const totalCost = insumoTable.reduce((total, record) => total + record.costo, 0);
  /*const totalRow = {
    key: 'total',
    description: 'TOTAL COSTO',
    cost: totalCost,
    render: (text, record) => (
      <span style={{color:"#0000ff"}}>{formatNumber(record.costo+'')}</span>
    )
  };*/
  const footer = () => (
    <tr>
      <td colSpan={filteredMenuItems.length - 1} >TOTAL COSTO : </td>
      <td style={{color:"#0000ff"}}><strong>{formatNumber(totalCost)}</strong></td>
    </tr>
  );

  return (
    <>

      {rowTable?.id ?
        <Space style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ fontSize: 13, margin: 0, color:"#0000ff" }}>{rowTable.categorianombre}: {rowTable.titulo}</span>
          <Space>
            <Button type="link" size="small" onClick={addInsumo} icon={<PlusOutlined style={{ color: "#0e3789" }} />} />
            <Tooltip title="Doble click en la fila para detalle" color="#0e3789" key="#0e3789">
              <Button type="link" size="small" icon={<QuestionOutlined style={{ color: "#0e3789" }} />} />
            </Tooltip>
          </Space>
        </Space>
        : <Typography.Text type="danger" style={{ fontSize: 13 }}>Debe seleccionar el producto</Typography.Text>}

      <Table
        dataSource={insumoTable}
        columns={filteredMenuItems}
        size="small"
        className="custom-table"
        pagination={false}
        loading={loading}
        onRow={(record) => ({
          onClick: () => handleClick(record),
        })}
        bordered
        footer={footer}

      />

      <ProductInsumoForm openModal={visible} closeModal={closeModal} titleModal={title} datosForm={initialValues} fetchData={geProductInsumos} />


    </>
  );
};
export default ProductInsumo;