/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button,   Space, Table, Tooltip, message } from "antd";
import {  PlusOutlined, QuestionOutlined, ReloadOutlined } from "@ant-design/icons";
import { getGastos, getProveedores } from "../../data/api";
import { useSelector } from "react-redux";
import GastoForm from "./GastoForm";
import { formatFecha, formatNumber } from "../../util";
import GastoFind from "./GastoFind";
//import Meta from "antd/es/card/Meta";
import dayjs from 'dayjs';
import CardGasto from "./CardGasto";


const GastoTable = () => {
    
    const [tableGasto, setTableGasto] = useState([]);
    const [proveedores, setProveedores] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [title, setTitle] = useState(false);
    const [totalGasto, setTotalGasto] = useState(0);
    const [totalCantidad, setTotalCantidad] = useState(0);
    const [descripcion, setDescripcion] = useState('');
    const [begin, setBegin] = useState(dayjs().startOf('month').format('YYYY-MM-DD'));
    const [end, setEnd] = useState(dayjs().endOf('month').format('YYYY-MM-DD'));
   
    const dataToken = useSelector(state => state.data.data);
    const valResponsive = useSelector(state => state.responsive);
    useEffect(() => {
        fetchGastos();
        fetchProveedores();
        //console.log('useEffect Cliente');
        setDescripcion(formatFecha(begin,end));
       
    }, [begin]);

    const fetchProveedores = async () => {
        try {
            const response = await getProveedores(dataToken.local);
            if (response.data.code === 0 && response.data.counts > 0) {
                const formattedData = response.data.results.map((item, index) => ({
                    ...item,
                    value: item.id,
                    label: item.nombre,
                    key: index
                }));

                setProveedores(formattedData);
            } else {
                setProveedores([]);
            }

        } catch (error) {
            message.error(error.message);
        }
        
    };

    const fetchGastos = async () => {
        setLoading(true);
        try {
            const response = await getGastos(dataToken.local,begin,end);
            if (response.data.code === 0 && response.data.counts > 0) {
                const totalGasto = response.data.results.reduce((acc, curr) => acc + curr.costo, 0);
                setTotalGasto(totalGasto);
                const totalCantidad = response.data.results.reduce((acc, curr) => acc + curr.cantidad, 0);
                setTotalCantidad(totalCantidad);
                setTableGasto(response.data.results);
            } else {
                setTableGasto([]);
                setTotalCantidad(0);
                setTotalGasto(0);
            }

        } catch (error) {
            message.error(error.message);
        }
        setLoading(false);
    };
    const addGasto = () => {
        //console.log('addCustomer');
        setVisible(true);
        setTitle('Nuevo Gasto');
        setInitialValues({ local: dataToken.local });
    }
    const closeModal = () => {
        setVisible(false);
    }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            mobile: false,
            width:80,
            sorter: (a, b) => a.id - b.id,
            
            
        },
        {
            title: 'Tipo',
            dataIndex: 'tipo',
            key: 'tipo',
            mobile: false,
            width:80
            
        },
        {
            title: 'Proveedor',
            dataIndex: 'nombreproveedor',
            key: 'nombreproveedor',
            mobile: true,
            ellipsis: true,
            width:100,
            
            
        },
        {
            title: 'Fecha',
            dataIndex: 'fecha',
            key: 'fecha',
            mobile: true,
            width:80,
            sorter: (a, b) =>a.fecha.localeCompare(b.fecha),
            defaultSortOrder: 'descend',
        },
        {
            title: '#',
            dataIndex: 'cantidad',
            key: 'cantidad',
            mobile: true,
            width:40
            
        },
        {
            title: 'Costo',
            dataIndex: 'costo',
            key: 'costo',
            mobile: true,
            width:80,
            render: (text, record) => (
                <span>{formatNumber(record.costo)}</span>
              )
        },
        {
            title: 'Referencia',
            dataIndex: 'referencia',
            key: 'referencia',
            mobile: false,
            width:100
            
        }, {
            title: 'Nro',
            dataIndex: 'numero',
            key: 'numero',
            mobile: false,
            width:100
            
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
            mobile: false,
            width:100
            
        },
        {
            title: 'Extra',
            dataIndex: 'extra',
            key: 'extra',
            mobile: false,
            ellipsis: true,
            width:100
            
        },
    ];

    const filteredMenuItems = columns.filter(item => {
        if (valResponsive) {
            if (item.mobile) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }

    });

    const [lastClickTime, setLastClickTime] = useState(0);

    const handleClick = (record) => {
        setInitialValues(record);
        const clickTime = new Date().getTime();
        const doubleClickDelay = 300;

        if (clickTime - lastClickTime < doubleClickDelay) {


            setVisible(true);
            setTitle('Actualizar Gasto');
        }

        setLastClickTime(clickTime);
    };
    
    const mostrar = (a,b)=>{
        //console.log('a',a);
        //console.log('b',b);
        setEnd(b);
        setBegin(a);
       
       
        setDescripcion(a+" Hasta "+b);
        

        
    }

      
    return (<>
<div style={{ paddingBottom: 5 }}>
        <Space style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <GastoFind mostrar={mostrar}/>
          <Space>
          <Button type="link"  size="small" onClick={fetchGastos} icon={<ReloadOutlined style={{color:"#0e3789"}} />}/>
            <Button type="link"  size="small" onClick={addGasto} icon={<PlusOutlined style={{color:"#0e3789"}} />}/>
            <Tooltip title="Doble click en la fila para detalle" color="#0e3789" key="#0e3789" >
                <Button type="link"  size="small" icon={<QuestionOutlined style={{color:"#0e3789"}} />}/>
             </Tooltip>
            </Space>
        </Space>

          
            
            </div>
            
        <Table
            dataSource={tableGasto}
            columns={filteredMenuItems}
            size="small"
            className="custom-table"
            pagination={{ showSizeChanger: true, showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} registros` }}
            loading={loading}
            onRow={(record) => ({
                onClick: () => handleClick(record),
            })}
            bordered

        />
     
        <CardGasto totalGasto={totalGasto} totalCantidad={totalCantidad} descripcion={descripcion}/>    
        <GastoForm openModal={visible} closeModal={closeModal} titleModal={title} datosForm={initialValues} fetchData={fetchGastos} proveedores={proveedores} />
    </>
    );
}
export default GastoTable;
