/* eslint-disable react-hooks/exhaustive-deps */
import {   Table, message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatNumber } from "../../util";
import { getCustomers } from "../../data/api";
import PedidoForm from "./PedidoForm";



const PedidoEnCurso = ({loading, pedidosTable, fetchPedidoEnCurso, tab}) => {
    const[clientes, setClientes]=useState([]);
    const[openModal, setOpenModal] = useState(false);
    const[initialValues, setInitialValues]=useState({});

    const valResponsive = useSelector(state => state.responsive);
    const dataToken = useSelector(state => state.data.data);


useEffect(()=>{

    
    fetchClientes();

},[]);

const closeModal = () => {
    setOpenModal(false);
}

const fetchClientes = async () => {
        
    try {
        
        const response = await getCustomers(dataToken.local);
        if (response.data.code === 0 && response.data.counts > 0) {
            setClientes(response);
        } else {
            setClientes([]);
        }
    } catch (error) {
        message.error(error.message);
    }
    

}


const columns = [
    {
        title: 'Nro',
        dataIndex: 'id',
        key: 'id',
        sorter: (a, b) => a.id - b.id,
        defaultSortOrder: 'descend',
        mobile: true,
        width: 60
    },
    {
        title: 'Cliente',
        dataIndex: 'clientealias',
        key: 'clientealias',
        mobile: true,
        width:100
    },
    {
        title: 'Detalle',
        dataIndex: 'descripcion',
        key: 'descripcion',
        mobile: false,
       
        render: (text, record) => (
            <span style={{color:"#2336b0"}}>{record.descripcion}</span>
          ),
          
    },
    {
        title: 'Hora',
        dataIndex: 'horafecha',
        key: 'horafecha',
        mobile: true,
        ellipsis: true,
        width:100
        
    },
    {
        title: 'Adelanto',
        dataIndex: 'parcial',
        key: 'parcial',
        mobile: false,
        render: (text, record) => (
            <span>{formatNumber(record.parcial)}</span>
          ),
          width: 60
    },
    {
        title: 'Delivery',
        dataIndex: 'delivery',
        key: 'delivery',
        mobile: false,
        render: (text, record) => (
            <span>{formatNumber(record.delivery)}</span>
          ),
          width: 60
    },
    {
        title: 'Descuen',
        dataIndex: 'descuento',
        key: 'descuento',
        mobile: false,
        render: (text, record) => (
            <span>{formatNumber(record.descuento)}</span>
          ),
          width: 60
    },
    {
        title: 'VentaF',
        dataIndex: 'venta',
        key: 'venta',
        mobile: false,
        render: (text, record) => (
            <span style={{color:"#2e42c7"}}><strong>{formatNumber(record.venta)}</strong></span>
          ),
          width: 60
    },
    {
        title: 'PorPagar',
        dataIndex: 'porpagar',
        key: 'porpagar',
        mobile: false,
        render: (text, record) => (
            <span style={{color:"#6e2308"}}><strong>{formatNumber(record.venta + record.delivery - record.parcial)}</strong></span>
          ),
          width: 60
    },
    {
        title: 'Estado',
        dataIndex: 'estado',
        key: 'estado',
        mobile: false,
        width: 100,


    },
    
    
];
const filteredMenuItems = columns.filter(item => {
    if (valResponsive) {
        if (item.mobile) {
            return true;
        } else {
            return false;
        }
    } else {
        return true;
    }

});

const [lastClickTime, setLastClickTime] = useState(0);

    const handleClick = (record) => {
        
        setInitialValues(record);
        //setInitialValues(record);
       //onClickRowTable(record);
        const clickTime = new Date().getTime();
        const doubleClickDelay = 300;

        if (clickTime - lastClickTime < doubleClickDelay) {
            //console.log('ENTRO');
            setOpenModal(true);
            //setVisible(true);
            //setTitle('Actualizar Producto');
        }

        setLastClickTime(clickTime);
    };

    


    return (

        <>
         <Table dataSource={pedidosTable}
                columns={filteredMenuItems}
                size="small"
                className="custom-table"
                //pageSize={20}
                //pagination={{ showSizeChanger: true, showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} registros` }}
                pagination={false}
                loading={loading}
                expandable={{
                    expandedRowRender: (record) => (
                      <p
                        style={{
                          margin: 0,
                          color:'#2336b0'
                        }}
                      >
                        {record.descripcion}
                      </p>
                    )
                  }}
                
                onRow={(record) => ({
                    onClick: () => handleClick(record),
                })}
            />
            <PedidoForm openModal={openModal} closeModal={closeModal}  datosForm={initialValues} fetchData={fetchPedidoEnCurso} clientes={clientes} tab={tab}/>
        </>
    );
};
export default PedidoEnCurso;