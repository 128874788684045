export const optionsFechas=[
    {
      value: 'date',
      label: 'Dia',
    },
    {
      value: 'week',
      label: 'Semana',
    },
    {
      value: 'month',
      label: 'Mes',
    },
   
    {
      value: 'year',
      label: 'Año',
    },
  ]; 