/* eslint-disable react-hooks/exhaustive-deps */
import { InfoCircleOutlined, SaveOutlined, UserOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, message } from "antd";
import { useEffect } from "react";
import { getLocal, localPost } from "../../data/api";
import { useSelector } from "react-redux";
import { UPDATE_NOK, UPDATE_OK } from "../../constans";

const Local = () => {
    const [formLocal] = Form.useForm();
    const dataToken = useSelector(state => state.data.data);
    useEffect(() => {
        
        const fetchLocal = async () => {
            try {

                const resp = await getLocal(dataToken.local);
                if (resp.data.code === 0) {
          
                    formLocal.setFieldsValue(resp.data.results[0]);
                } else {
                    message.error('Error: Code' + resp.data.code);
                }

            } catch (error) {
                message.error(error.message);
            }
        };
        fetchLocal();

    }, []);


    const onFinish = async (values) => {

        try{
            const response = await localPost(values);
            if (response.data.code === 0) {
                if (response.data.result.code === 0) {
                    message.success(UPDATE_OK);
                    
                } else {
                    message.error(UPDATE_NOK + response.data.message);
                }
            } else {
                message.error(UPDATE_NOK + response.data.message);
            }

        }catch(error){
            message.error(error.message);
        }
    }
    return (
        <>
            <Card>

                <Form
                    form={formLocal}
                    name="formLocal"
                    labelCol={{
                        flex: '60px',
                    }}
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{
                        flex: 1,
                    }}
                    colon={false}
                    style={{
                        maxWidth: 600,
                        fontSize: 10,
                    }}
                    size="small"
                    onFinish={onFinish}

                >
                    <Form.Item name="id" label="Id" hidden  >
                        <Input  />
                    </Form.Item>
                    <Form.Item name="nombre" label="Razón" style={{ fontSize:10,marginBottom: '6px' }}  >
                        <Input prefix={<UserOutlined />} />
                    </Form.Item>
                    <Form.Item name="direccion" label="Lugar" style={{ marginBottom: '6px' }} >
                        <Input.TextArea  />
                    </Form.Item> <Form.Item name="celular" label="Celular" style={{ marginBottom: '6px' }} >
                        <Input prefix={<WhatsAppOutlined />} />
                    </Form.Item>
                    <Form.Item name="extra" label="Extra" style={{ marginBottom: '6px' }} >
                        <Input prefix={<InfoCircleOutlined />} />
                    </Form.Item>
                    <Form.Item name="logo" label="Logo" style={{ marginBottom: '6px' }} >
                        <Input readOnly/>
                    </Form.Item>
                    <Form.Item label=" ">
                    <div style={{ paddingBottom: 5, float:'right' }}>
                        
                        <Button type="primary" htmlType="submit">
                            <SaveOutlined /> Actualizar
                        </Button>
                    </div>
                    </Form.Item>

                </Form>

            </Card>
        </>
    );
}
export default Local;