import {  FallOutlined } from "@ant-design/icons";
import { Avatar, Card } from "antd";
import Meta from "antd/es/card/Meta";
import { formatNumber } from "../../util";

const CardGasto = ({totalGasto, totalCantidad, descripcion})=>{

    return (
<Card
        style={{
          width: 300,
          marginTop: 16,
          fontSize:11
        }}
        size="small"
      >
        <Meta 
          avatar={<Avatar icon={<FallOutlined   /> }  style={{color:"white",backgroundColor: '#9c0a00',}}/>}
          title={`Gasto : ${formatNumber(totalGasto)}`}
         description={<div><ul><li>{descripcion}</li><li>Total de Articulos {totalCantidad}</li></ul></div>}
         
                 />
                 
      </Card>
    );
};
export default CardGasto;