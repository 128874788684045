/* eslint-disable react-hooks/exhaustive-deps */
import {   Space, Table, message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatNumber } from "../../util";
import { getCustomers } from "../../data/api";
import PedidoForm from "./PedidoForm";
import VentaFind from "../ventas/VentaFind";
//import dayjs from 'dayjs';


const PedidoPagado = ({loading, pedidosTable, fetchPedidoPagado,tab, descripcion, mostrar}) => {
    const[clientes, setClientes]=useState([]);
    const[openModal, setOpenModal] = useState(false);
    const[initialValues, setInitialValues]=useState({});
    
    


    const valResponsive = useSelector(state => state.responsive);
    const dataToken = useSelector(state => state.data.data);


useEffect(()=>{

    
    fetchClientes();
    //setDescripcion(formatFecha(dayjs().startOf('month').format('YYYY-MM-DD'),dayjs().endOf('month').format('YYYY-MM-DD')));
   // console.log('useEffect PedidoPagado');
},[]);

const closeModal = () => {
    setOpenModal(false);
}

const fetchClientes = async () => {
        
    try {
        
        const response = await getCustomers(dataToken.local);
        if (response.data.code === 0 && response.data.counts > 0) {
            setClientes(response);
        } else {
            setClientes([]);
        }
    } catch (error) {
        message.error(error.message);
    }
    

}


const columns = [
    {
        title: 'Nro',
        dataIndex: 'id',
        key: 'id',
        sorter: (a, b) => a.id - b.id,
        defaultSortOrder: 'descend',
        mobile: true,
        width: 60
    },
    {
        title: 'Cliente',
        dataIndex: 'clientealias',
        key: 'clientealias',
        mobile: true,
        width:100
    },
    {
        title: 'Detalle',
        dataIndex: 'descripcion',
        key: 'descripcion',
        mobile: false,
        
        render: (text, record) => (
            <span style={{color:"#2336b0"}}>{record.descripcion}</span>
          ),
          
    },
    {
        title: 'Hora',
        dataIndex: 'horafecha',
        key: 'horafecha',
        mobile: false,
        //ellipsis: true,
        width:100
        
    },
    {
        title: 'Adelanto',
        dataIndex: 'parcial',
        key: 'parcial',
        mobile: false,
        render: (text, record) => (
            <span>{formatNumber(record.parcial)}</span>
          ),
          width: 60
    },
    {
        title: 'Delivery',
        dataIndex: 'delivery',
        key: 'delivery',
        mobile: false,
        render: (text, record) => (
            <span>{formatNumber(record.delivery)}</span>
          ),
          width: 60
    },
    {
        title: 'Descuen',
        dataIndex: 'descuento',
        key: 'descuento',
        mobile: false,
        render: (text, record) => (
            <span>{formatNumber(record.descuento)}</span>
          ),
          width: 60
    },
    {
        title: 'Pago',
        dataIndex: 'venta',
        key: 'venta',
        mobile: true,
        render: (text, record) => (
            <span style={{color:"#2e42c7"}}><strong>{formatNumber(record.venta)}</strong></span>
          ),
          width: 60
    },
   
    {
        title: 'Modo',
        dataIndex: 'modo',
        key: 'modo',
        mobile: false,
        width:120,
   
        
    },
    {
        title: 'Obs',
        dataIndex: 'extra',
        key: 'extra',
        mobile: false,
        ellipsis:true,
        width:100
        
    },
    
    
];
const filteredMenuItems = columns.filter(item => {
    if (valResponsive) {
        if (item.mobile) {
            return true;
        } else {
            return false;
        }
    } else {
        return true;
    }

});

const [lastClickTime, setLastClickTime] = useState(0);

    const handleClick = (record) => {
       
        setInitialValues(record);
        //setInitialValues(record);
       //onClickRowTable(record);
        const clickTime = new Date().getTime();
        const doubleClickDelay = 300;

        if (clickTime - lastClickTime < doubleClickDelay) {
            //console.log('ENTRO');
            setOpenModal(true);
            //setVisible(true);
            //setTitle('Actualizar Producto');
        }

        setLastClickTime(clickTime);
    };


    return (

        <>
         <div style={{ paddingBottom: 3}}>
        <Space style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <VentaFind mostrar={mostrar}/>
          <Space>
            <span style={{fontSize:12}}>{descripcion}</span>
          </Space>
        </Space>
        </div>

        
         <Table dataSource={pedidosTable}
                columns={filteredMenuItems}
                size="small"
                className="custom-table"
                pageSize={20}
                pagination={{ showSizeChanger: true, showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} registros` }}
                loading={loading}
                expandable={{
                    expandedRowRender: (record) => (
                      <p
                        style={{
                          margin: 0,
                          color:'#2336b0'
                        }}
                      >
                        {record.descripcion}
                      </p>
                    )
                  }}
                
                onRow={(record) => ({
                    onClick: () => handleClick(record),
                })}
            />
            <PedidoForm openModal={openModal} closeModal={closeModal}  datosForm={initialValues} fetchData={fetchPedidoPagado} clientes={clientes} tab={tab}/>
        </>
    );
};
export default PedidoPagado;