

export const formatNumber = (number) => {
  if (number === null || isNaN(number)) {
    return "$0"; // Retorna "$0" en caso de ser null o no ser un número
  }
    // Convierte el número en una cadena y divide la cadena en partes
    const parts = number.toString().split(".");
    // Formatea la parte entera del número con separadores de miles
    const formattedInteger = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    // Si hay parte decimal, agrégala al número formateado
    const formattedNumber = parts.length === 2 ? `${formattedInteger},${parts[1]}` : formattedInteger;
    // Agrega el símbolo de moneda al principio y retorna el resultado
    return `$${formattedNumber}`;
  };
export const formatFecha = (begin,end) =>{

  const monthNames = [
    "Enero", "Febrero", "Marzo",
    "Abril", "Mayo", "Junio", "Julio",
    "Agosto", "Septiembre", "Octubre",
    "Noviembre", "Diciembre"
  ];
  const monthNamesShort = [
    "Ene", "Feb", "Mar",
    "Abr", "May", "Jun", "Jul",
    "Ago", "Sep", "Oct",
    "Nov", "Dic"
  ];

  const parts = begin.split('-');
  const month = parseInt(parts[1], 10)-1;
  const partsEnd = end.split('-');
  const monthEnd = parseInt(partsEnd[1], 10)-1;
  
  if(parts[2]==='01' && partsEnd[2]==='31' && parts[1]===partsEnd[1]){
    return monthNames[month]+" "+parts[0];
  }
  if(parts[2]==='01' && partsEnd[2]==='30' && parts[1]===partsEnd[1]){
    return monthNames[month]+" "+parts[0];
  }
  if(parts[2]==='01' && (partsEnd[2]==='28' || partsEnd[2]==='29') && parts[1]===partsEnd[1] && parts[1]==='02'){
    return monthNames[month]+" "+parts[0];
  }
  if(begin === end){
    return parts[2]+" de "+monthNames[month];
  }
  if(parts[2]==='01' && parts[1]==='01' && partsEnd[2]==='31' && partsEnd[1]==='12'){
    return "Año "+parts[0];
  }
  if(begin !== end){
    return parts[2]+" "+monthNamesShort[month]+" a "+partsEnd[2]+" "+monthNamesShort[monthEnd];
  }

}
