/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, InputNumber, Space,  message } from 'antd';
import React, { useEffect, useState } from 'react'

import { gastoDetallePost, getGastoDetalle } from '../../data/api';
import { CREATE_NOK, CREATE_OK } from '../../constans';
import GastoDetailsTable from './GastoDetailsTable';
import { ClearOutlined } from '@ant-design/icons';

const GastoDetails = ({idGasto, fetchData}) => {
  const [loadTable, setLoadTable] = useState(false);
    const[gdTable,setGdTable] = useState([]);

  const[formGastoDetalle] = Form.useForm();
  useEffect(()=>{
   // console.log('useEffect GastoDetails');
    fetchGD();


  },[idGasto]);
  const handleSubmit = async (values) => {
    values['gasto']=idGasto;
    //console.log(values);
    try{
      const response = await gastoDetallePost(values);
      if (response.data.code === 0) {
        if (response.data.result.code === 0) {
          message.success(CREATE_OK);
          fetchGD();
          cleanForm();
          fetchData();
        }else{
          message.error(CREATE_NOK+" "+response.data.message);
        }
      }else{
        message.error(CREATE_NOK+" "+response.data.message);
      }

    }catch(error){
      message.error(error.message);
    }
  }

  const cleanForm = () => {
    formGastoDetalle.resetFields();
  }
  const fetchGD = async () => {
    setLoadTable(true);
    try{
      const response = await getGastoDetalle(idGasto);
      //console.log(response); 
      if (response.data.code === 0 && response.data.counts > 0) {
      
        const total = response.data.results.reduce((acc, curr) => acc + curr.costo, 0);
        const addTotal = [...response.data.results, { id: '',key:-1, cantidad:'==>',concepto: 'Total', costo: total }];
        setGdTable(addTotal);
    } else {
       setGdTable([]);
    }
    }catch(error){
      message.error(error.message);
    }
    setLoadTable(false);

  }

 
  return (
    <>
    <span style={{color:"#0e3789"}}><strong>Detalle del Gasto Nro {idGasto}</strong></span>
      <Form
        form={formGastoDetalle}
        name="GastoDetalleForm"
        labelCol={{
          flex: '100px',
        }}
        labelAlign="left"
        labelWrap
        wrapperCol={{
          flex: 1,
        }}
        colon={false}
        style={{
          maxWidth: 600,
          fontSize: 8
        }}
        size="small"
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <br></br>
        <Form.Item
          label="Id"
          name="gasto"
          hidden
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Cantidad"
          name="cantidad"
          rules={[
            {
                required: true,
                message: 'Por favor ingresar',
            },
        ]}
          style={{ marginBottom: '4px' }} 
        >
          <InputNumber min={0} />
        </Form.Item>
        
        <Form.Item
          label="Concepto"
          name="concepto"
          rules={[
            {
                required: true,
                message: 'Por favor ingresar',
            },
        ]}
          style={{ marginBottom: '4px' }} 
        >
          <Input.TextArea  />
        </Form.Item>
        <Form.Item
          label="Costo"
          name="costo"
          rules={[
            {
                required: true,
                message: 'Por favor ingresar',
            },
        ]}

          style={{ marginBottom: '4px' }} 
        >
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item label=" ">
          <div style={{ paddingBottom: 5, float: 'right' }}>
            <Space align="end">
              <Button type="dashed" onClick={cleanForm}>
                <ClearOutlined  />
              </Button>
              <Button type="primary" htmlType="submit">
                Agregar
              </Button>

            </Space>
          </div>

        </Form.Item>
      </Form>
      <GastoDetailsTable idGasto={idGasto} loadTable={loadTable} gdTable={gdTable} setLoadTable={setLoadTable} setGdTable={setGdTable} fetchData={fetchData} />

      
    </>
  );
};

export default GastoDetails;
