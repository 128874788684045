import React from "react";
import { Tabs } from "antd";
import { UsergroupAddOutlined, HomeOutlined } from "@ant-design/icons";
import Users from "./Users";
import Local from "./Local";
const System = () => {
    const itemsTab = [
        {
            key: 1,
            label: 'Lista de Usuarios',
            children: <Users />,
            icon: <UsergroupAddOutlined style={{ color: "#0e3789" }} />,
        },
        {
            key: 2,
            label: 'Datos Empresa',
            children: <Local />,
            icon: <HomeOutlined style={{ color: "#0e3789" }}/>,
        },
    ];
    return (<Tabs
        defaultActiveKey="2"
        size='small'
        style={{
            marginBottom: 24,

        }}
        items={itemsTab}
    />);
}
export default System;