import axios from 'axios';
const URI = 'https://irest.cl/api-rest-mercu';
/** Tabla Usuario **/
export const auth = (request) => axios.post(`${URI}/auth`, request, {
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'multipart/form-data'
  }
});
export const getUsersLocal = (local) => {
  const config = {
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${localStorage.getItem('tk')}`
    }
  }
  const timestamp = Date.now();
  const request = {
    timestamp: timestamp,
  }
  return axios.get(`${URI}/userslocal/${local}`, config, request);
};
export const userDel = (id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('tk')}`,
    }
  };
  return axios.delete(`${URI}/user/${id}`, config);
};
export const userPost = (request) => axios.post(`${URI}/user`, request, {
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${localStorage.getItem('tk')}`,
  }
});
export const changePassPost = (request) => axios.post(`${URI}/changePassword`, request, {
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${localStorage.getItem('tk')}`,
  }
});

export const userPut = (id, request) => axios.put(`${URI}/user/${id}`, request, {
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': `Bearer ${localStorage.getItem('tk')}`,
  },
  transformRequest: [(data) => {
    const formData = new URLSearchParams();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    return formData;
  }]
}
);

/** Fin Tabla Usuario**/
/** Tabla Local */
export const getLocal = (local) => {
  const config = {
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${localStorage.getItem('tk')}`
    }
  }
  const timestamp = Date.now();
  const request = {
    timestamp: timestamp,
  }
  return axios.get(`${URI}/local/${local}`, config, request);
};
export const localPost = (request) => axios.post(`${URI}/updateLocal`, request, {
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${localStorage.getItem('tk')}`,
  }
});
/** Fin Tabla Local**/
/** Tabla Clientes */
export const getCustomers = (local) => {
  const config = {
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${localStorage.getItem('tk')}`
    }
  }
  const timestamp = Date.now();
  const request = {
    timestamp: timestamp,
  }
  return axios.get(`${URI}/customers/${local}`, config, request);
};
export const customerDel = (id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('tk')}`,
    }
  };
  return axios.delete(`${URI}/customer/${id}`, config);
};

export const customerPost = (request) => axios.post(`${URI}/customer`, request, {
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${localStorage.getItem('tk')}`,
  }
});

export const customerPut = (id, request) => axios.put(`${URI}/customer/${id}`, request, {
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': `Bearer ${localStorage.getItem('tk')}`,
  },
  transformRequest: [(data) => {
    const formData = new URLSearchParams();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    return formData;
  }]
}
);
/** Fin Tabla Clientes**/
/** Tabla Productos */
export const getProducts = (local) => {
  const config = {
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${localStorage.getItem('tk')}`
    }
  }
  const timestamp = Date.now();
  const request = {
    timestamp: timestamp,
  }
  return axios.get(`${URI}/products/${local}`, config, request);
};

export const getProductsActivos = (local) => {
  const config = {
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${localStorage.getItem('tk')}`
    }
  }
  const timestamp = Date.now();
  const request = {
    timestamp: timestamp,
  }
  return axios.get(`${URI}/productsactivos/${local}`, config, request);
};

export const productPost = (request) => axios.post(`${URI}/product`, request, {
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${localStorage.getItem('tk')}`,
  }
});

export const productPut = (id, request) => axios.put(`${URI}/product/${id}`, request, {
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': `Bearer ${localStorage.getItem('tk')}`,
  },
  transformRequest: [(data) => {
    const formData = new URLSearchParams();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    return formData;
  }]
}
);
export const productDel = (id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('tk')}`,
    }
  };
  return axios.delete(`${URI}/product/${id}`, config);
};
/** Fin Tabla Productos */
/**Tabla Categoria */

export const getCategories = (local) => {
  const config = {
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${localStorage.getItem('tk')}`
    }
  }
  const timestamp = Date.now();
  const request = {
    timestamp: timestamp,
  }
  return axios.get(`${URI}/categories/${local}`, config, request);
};

export const categoryPost = (request) => axios.post(`${URI}/category`, request, {
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${localStorage.getItem('tk')}`,
  }
});

export const categoryPut = (id, request) => axios.put(`${URI}/category/${id}`, request, {
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': `Bearer ${localStorage.getItem('tk')}`,
  },
  transformRequest: [(data) => {
    const formData = new URLSearchParams();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    return formData;
  }]
}
);
export const categoryDel = (id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('tk')}`,
    }
  };
  return axios.delete(`${URI}/category/${id}`, config);
};
/***Fin Tabla Categoria */

/**Tabla Insumo */

export const getInsumos = (producto) => {
  const config = {
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${localStorage.getItem('tk')}`
    }
  }
  const timestamp = Date.now();
  const request = {
    timestamp: timestamp,
  }
  return axios.get(`${URI}/insumos/${producto}`, config, request);
};

export const insumoPost = (request) => axios.post(`${URI}/insumo`, request, {
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${localStorage.getItem('tk')}`,
  }
});

export const insumoPut = (id, request) => axios.put(`${URI}/insumo/${id}`, request, {
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': `Bearer ${localStorage.getItem('tk')}`,
  },
  transformRequest: [(data) => {
    const formData = new URLSearchParams();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    return formData;
  }]
}
);
export const insumoDel = (id, product) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('tk')}`,
    }
  };
  return axios.delete(`${URI}/insumo/${id}/${product}`, config);
};
/***Fin Tabla Insumo */

/**Tabla Elabora */

export const getElaboras = (producto) => {
  const config = {
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${localStorage.getItem('tk')}`
    }
  }
  const timestamp = Date.now();
  const request = {
    timestamp: timestamp,
  }
  return axios.get(`${URI}/elaboras/${producto}`, config, request);
};

export const elaboraPost = (request) => axios.post(`${URI}/elabora`, request, {
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${localStorage.getItem('tk')}`,
  }
});

export const elaboraPut = (id, request) => axios.put(`${URI}/elabora/${id}`, request, {
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': `Bearer ${localStorage.getItem('tk')}`,
  },
  transformRequest: [(data) => {
    const formData = new URLSearchParams();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    return formData;
  }]
}
);
export const elaboraDel = (id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('tk')}`,
    }
  };
  return axios.delete(`${URI}/elabora/${id}`, config);
};
/***Fin Tabla Elabora */

/***Tabla Pedido y PedProd */

export const pedidoPost = (request) => axios.post(`${URI}/pedido`, request, {
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${localStorage.getItem('tk')}`,
  }
});

export const pedprodupdatePost = (request) => axios.post(`${URI}/pedprodupdate`, request, {
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${localStorage.getItem('tk')}`,
  }
});

export const pedprodPost = (request) => axios.post(`${URI}/pedprod`, request, {
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${localStorage.getItem('tk')}`,
  }
});

export const getPedidosEnCurso = (local) => {
  const config = {
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${localStorage.getItem('tk')}`
    }
  }
  const timestamp = Date.now();
  const request = {
    timestamp: timestamp,
  }
  return axios.get(`${URI}/pedidoencurso/${local}`, config, request);
};
export const getPedidosDebe = (local) => {
  const config = {
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${localStorage.getItem('tk')}`
    }
  }
  const timestamp = Date.now();
  const request = {
    timestamp: timestamp,
  }
  return axios.get(`${URI}/pedidodebe/${local}`, config, request);
};
export const getPedidosPagado = (local,begin,end) => {
  const config = {
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${localStorage.getItem('tk')}`
    }
  }
  const timestamp = Date.now();
  const request = {
    timestamp: timestamp,
  }
  return axios.get(`${URI}/pedidopagado/${local}/${begin}/${end}`, config, request);
};
export const getPedidoProductos = (pedido) => {
  const config = {
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${localStorage.getItem('tk')}`
    }
  }
  const timestamp = Date.now();
  const request = {
    timestamp: timestamp,
  }
  return axios.get(`${URI}/pedprod/${pedido}`, config, request);
};
export const pedidoPut = (id, request) => axios.put(`${URI}/pedido/${id}`, request, {
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': `Bearer ${localStorage.getItem('tk')}`,
  },
  transformRequest: [(data) => {
    const formData = new URLSearchParams();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    return formData;
  }]
}
);

export const pedidoDel = (id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('tk')}`,
    }
  };
  return axios.delete(`${URI}/pedido/${id}`, config);
};

export const pedidoDetalleDel = (id,pedido,producto,cantidad,inventario) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('tk')}`,
    }
  };
  return axios.delete(`${URI}/pedidodet/${id}/${pedido}/${producto}/${cantidad}/${inventario}`, config);
};

export const pedprodDel = (id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('tk')}`,
    }
  };
  return axios.delete(`${URI}/pedprod/${id}`, config);
};


/***Fin Tabla */

/** Tabla Proveedores */
export const getProveedores = (local) => {
  const config = {
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${localStorage.getItem('tk')}`
    }
  }
  const timestamp = Date.now();
  const request = {
    timestamp: timestamp,
  }
  return axios.get(`${URI}/proveedores/${local}`, config, request);
};
export const proveedorDel = (id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('tk')}`,
    }
  };
  return axios.delete(`${URI}/proveedor/${id}`, config);
};

export const proveedorPost = (request) => axios.post(`${URI}/proveedor`, request, {
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${localStorage.getItem('tk')}`,
  }
});

export const proveedorPut = (id, request) => axios.put(`${URI}/proveedor/${id}`, request, {
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': `Bearer ${localStorage.getItem('tk')}`,
  },
  transformRequest: [(data) => {
    const formData = new URLSearchParams();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    return formData;
  }]
}
);
/** Fin Tabla Proveedores**/
/**Tabla Gastos y GastoDetalle */
export const getGastosLista = (local,begin,end) => {
  const config = {
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${localStorage.getItem('tk')}`
    }
  }
  const timestamp = Date.now();
  const request = {
    timestamp: timestamp,
  }
  return axios.get(`${URI}/gastoslista/${local}/${begin}/${end}`, config, request);
};

export const getGastos = (local,begin,end) => {
  const config = {
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${localStorage.getItem('tk')}`
    }
  }
  const timestamp = Date.now();
  const request = {
    timestamp: timestamp,
  }
  return axios.get(`${URI}/gastos/${local}/${begin}/${end}`, config, request);
};
export const gastoDel = (id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('tk')}`,
    }
  };
  return axios.delete(`${URI}/gasto/${id}`, config);
};

export const gastoPost = (request) => axios.post(`${URI}/gasto`, request, {
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${localStorage.getItem('tk')}`,
  }
});

export const gastoPut = (id, request) => axios.put(`${URI}/gasto/${id}`, request, {
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': `Bearer ${localStorage.getItem('tk')}`,
  },
  transformRequest: [(data) => {
    const formData = new URLSearchParams();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    return formData;
  }]
}
);

export const getGastoDetalle = (gasto) => {
  const config = {
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${localStorage.getItem('tk')}`
    }
  }
  const timestamp = Date.now();
  const request = {
    timestamp: timestamp,
  }
  return axios.get(`${URI}/gastodetalle/${gasto}`, config, request);
};
export const gastoDetalleDel = (id,gasto) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('tk')}`,
    }
  };
  return axios.delete(`${URI}/gastodet/${id}/${gasto}`, config);
};

export const gastoDetallePost = (request) => axios.post(`${URI}/gastodetalle`, request, {
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${localStorage.getItem('tk')}`,
  }
});

export const gastoDetallePut = (id, request) => axios.put(`${URI}/gastodetalle/${id}`, request, {
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': `Bearer ${localStorage.getItem('tk')}`,
  },
  transformRequest: [(data) => {
    const formData = new URLSearchParams();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    return formData;
  }]
}
);

export const updatecostpedprodPut = (id, request) => axios.put(`${URI}/updatecostpedprod/${id}`, request, {
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': `Bearer ${localStorage.getItem('tk')}`,
  },
  transformRequest: [(data) => {
    const formData = new URLSearchParams();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    return formData;
  }]
}
);

export const updatecostpedidoPut = (id, request) => axios.put(`${URI}/updatecostpedido/${id}`, request, {
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': `Bearer ${localStorage.getItem('tk')}`,
  },
  transformRequest: [(data) => {
    const formData = new URLSearchParams();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    return formData;
  }]
}
);

/**Fin Gastos y Gastos Detalle */
export const getEstadistica = (local,beginDate,endDate) => {
  const config = {
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${localStorage.getItem('tk')}`
    }
  }
  const timestamp = Date.now();
  const request = {
    timestamp: timestamp,
  }
  return axios.get(`${URI}/estadistica/${local}/${beginDate}/${endDate}`, config, request);
};
export const getEstadistica2 = (local,beginDate,endDate) => {
  const config = {
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${localStorage.getItem('tk')}`
    }
  }
  const timestamp = Date.now();
  const request = {
    timestamp: timestamp,
  }
  return axios.get(`${URI}/estadistica2/${local}/${beginDate}/${endDate}`, config, request);
};
export const getLoMasVendido = (local,beginDate,endDate,limite) => {
  const config = {
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${localStorage.getItem('tk')}`
    }
  }
  const timestamp = Date.now();
  const request = {
    timestamp: timestamp,
  }
  return axios.get(`${URI}/lomasvendido/${local}/${beginDate}/${endDate}/${limite}`, config, request);
};
export const getClientesPremium = (local,beginDate,endDate,limite) => {
  const config = {
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${localStorage.getItem('tk')}`
    }
  }
  const timestamp = Date.now();
  const request = {
    timestamp: timestamp,
  }
  return axios.get(`${URI}/clientespremium/${local}/${beginDate}/${endDate}/${limite}`, config, request);
};
export const getResumenVentas = (local,beginDate,endDate) => {
  const config = {
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${localStorage.getItem('tk')}`
    }
  }
  const timestamp = Date.now();
  const request = {
    timestamp: timestamp,
  }
  return axios.get(`${URI}/resumenventas/${local}/${beginDate}/${endDate}`, config, request);
};
export const getRegularizaCosto = (local,beginDate,endDate) => {
  const config = {
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${localStorage.getItem('tk')}`
    }
  }
  const timestamp = Date.now();
  const request = {
    timestamp: timestamp,
  }
  return axios.get(`${URI}/regularizacosto/${local}/${beginDate}/${endDate}`, config, request);
};

export const getPedidoClienteDebe = (local) => {
  const config = {
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${localStorage.getItem('tk')}`
    }
  }
  const timestamp = Date.now();
  const request = {
    timestamp: timestamp,
  }
  return axios.get(`${URI}/pedidoclientedebe/${local}`, config, request);
};


