import React from "react";
import { Tabs } from "antd";
import {FolderAddOutlined } from "@ant-design/icons";
import CategoriaTable from "./CategoriaTable";


const Categoria = () => {
    const itemsTab = [
        {
            key: 1,
            label: 'Categorias',
            children: <CategoriaTable />,
            icon: <FolderAddOutlined style={{color:"#0e3789"}} />,
        },
    ];
    return (<Tabs
        defaultActiveKey="1"
        size='small'
        style={{
            marginBottom: 24,

        }}
        items={itemsTab}
    />);
}
export default Categoria;