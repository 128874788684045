/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Input, Space, Spin, Typography, message, Form } from "antd";
import { useEffect } from "react";
import { getElaboras,  elaboraPost, elaboraPut } from "../../data/api";
import { SaveOutlined } from "@ant-design/icons";
import { CREATE_NOK, CREATE_OK, UPDATE_NOK, UPDATE_OK } from "../../constans";

const ProductPrepare = ({ rowTable, prepareText, loading, setPrepareText, setLoading }) => {

   // const[idPrepare,setIdPrepare]=useState('');
    
    const [formProductPrepare] = Form.useForm();
    
    useEffect(() => {
         formProductPrepare.setFieldsValue(prepareText);
        
    }, [prepareText]);


    const handleSave = async () => {
        const values = {id:prepareText.id,producto:prepareText.producto,nombre:formProductPrepare.getFieldValue('nombre')};
        setLoading(true);
        try {
            let response = [];
            values['id'] === '' ? response = await elaboraPost(values) :
            response = await elaboraPut(values['id'], values);
            if (response.data.code === 0) {
                if (response.data.result.code === 0) {
                    message.success(values['id'] === '' ? CREATE_OK : UPDATE_OK);
                    fetchElaboras();
                } else {
                    message.error(values['id'] === '' ? CREATE_NOK + response.data.message : UPDATE_NOK + response.data.message);
                }
            } else {
                message.error(values['id'] === '' ? CREATE_NOK + response.data.message : UPDATE_NOK + response.data.message);
            }

        } catch (error) {
            
            message.error(CREATE_NOK + error.message);
        }
        setLoading(false);
        
        
        //formProductPrepare.submit();
    }
    const fetchElaboras = async () => {
        
        setLoading(true);
        try {
            const response = await getElaboras(rowTable.id);
            if (response.data.code === 0 && response.data.counts > 0) {

                setPrepareText(response.data.results[0]);
                formProductPrepare.setFieldValue({nombre:response.data.results[0].nombre});
                //formProductPrepare.setFieldsValue(response.data.results[0]);

            } else {
                setPrepareText({id:'',producto:rowTable.id});
                //formProductPrepare.setFieldsValue({id:'',producto:rowTable.id,nombre:''});
            }

        } catch (error) {
            message.error(error.message);
        }
        setLoading(false);
    }



    

    const handleFinish = async (values) => {
        
        for (const key in values) {
            if (values[key] === undefined) {
                values[key] = '';
            }
        }
        setLoading(true);
        
        try {
            let response = [];
            values['id'] === '' ? response = await elaboraPost(values) :
            response = await elaboraPut(values['id'], values);
            if (response.data.code === 0) {
                if (response.data.result.code === 0) {
                    message.success(values['id'] === '' ? CREATE_OK : UPDATE_OK);
                    fetchElaboras();
                } else {
                    message.error(values['id'] === '' ? CREATE_NOK + response.data.message : UPDATE_NOK + response.data.message);
                }
            } else {
                message.error(values['id'] === '' ? CREATE_NOK + response.data.message : UPDATE_NOK + response.data.message);
            }

        } catch (error) {
            
            message.error(CREATE_NOK + error.message);
        }
        setLoading(false);
    }

    return (
        <>

            {rowTable?.id ?
                <Space style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ fontSize: 13, margin: 0, color: "#0000ff" }}>{rowTable.categorianombre}: {rowTable.titulo}</span>
                    <Space>
                        <Button type="link" size="small" onClick={handleSave} icon={<SaveOutlined style={{ color: "#0e3789" }} />} />
                    </Space>
                </Space>
                : <Typography.Text type="danger" style={{ fontSize: 13 }}>Debe seleccionar el producto</Typography.Text>}

            <Spin spinning={loading}>
                <Form
                    form={formProductPrepare}
                   
                    name="productPrepareForm"
                    colon={false}
                    size="small"
                    onFinish={handleFinish}
                    autoComplete="off"
                    style={{
                        maxWidth: 600,
                        fontSize: 8
                    }}
                    labelCol={{
                        flex: '0px',
                    }}
               
                  
                    wrapperCol={{
                        flex: 1,
                    }}
                    
                >
                    <Form.Item
                        label="Id"
                        name="id"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Producto"
                        name="producto"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label=" "
                        name="nombre"
                        style={{ marginBottom: '6px' }}
                    >
                        <Input.TextArea autoSize={{ minRows: 10 }} style={{fontSize:13}}/>
                    </Form.Item>
                </Form>
            </Spin>
        </>
    );
};
export default ProductPrepare;