/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button,  Space, Table, Tooltip, message } from "antd";
import { PlusOutlined, QuestionOutlined } from "@ant-design/icons";
import { getProveedores } from "../../data/api";
import { useSelector } from "react-redux";
import ProveedorForm from "./ProveedorForm";



const ProveedorTable = () => {
    
    const [tableProveedor, setTableProveedor] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [title, setTitle] = useState(false);
    const dataToken = useSelector(state => state.data.data);
    const valResponsive = useSelector(state => state.responsive);
    useEffect(() => {
        fetchProveedores();
        //console.log('useEffect Cliente');
    }, []);

    const fetchProveedores = async () => {
        setLoading(true);
        try {
            const response = await getProveedores(dataToken.local);
            if (response.data.code === 0 && response.data.counts > 0) {
                const formattedData = response.data.results.map((item, index) => ({
                    ...item,
                    key: index,
                }));
                setTableProveedor(formattedData);
            } else {
                setTableProveedor([]);
            }

        } catch (error) {
            message.error(error.message);
        }
        setLoading(false);
    };
    const addProveedor = () => {
        //console.log('addCustomer');
        setVisible(true);
        setTitle('Nuevo Proveedor');
        setInitialValues({ local: dataToken.local });
    }
    const closeModal = () => {
        setVisible(false);
    }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            mobile: false,
            width:80,
            sorter: (a, b) => a.id - b.id,
            defaultSortOrder: 'descend',
            
        },
        {
            title: 'Tipo',
            dataIndex: 'tipo',
            key: 'tipo',
            mobile: false,
            width:80
            
        },
        {
            title: 'Nombres',
            dataIndex: 'nombre',
            key: 'nombre',
            mobile: true,
            
        },
        {
            title: 'Direccion',
            dataIndex: 'direccion',
            key: 'direccion',
            mobile: true,
            ellipsis: true,
            
        },
        
        {
            title: 'Contacto',
            dataIndex: 'celular1',
            key: 'celular1',
            mobile: true,
            width:100
            
        }, {
            title: 'Contacto1',
            dataIndex: 'celular2',
            key: 'celular2',
            mobile: false,
            
        },
        {
            title: 'Extra',
            dataIndex: 'extra',
            key: 'extra',
            mobile: false,
            
        },
    ];

    const filteredMenuItems = columns.filter(item => {
        if (valResponsive) {
            if (item.mobile) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }

    });

    const [lastClickTime, setLastClickTime] = useState(0);

    const handleClick = (record) => {
        setInitialValues(record);
        const clickTime = new Date().getTime();
        const doubleClickDelay = 300;

        if (clickTime - lastClickTime < doubleClickDelay) {


            setVisible(true);
            setTitle('Actualizar Proveedor');
        }

        setLastClickTime(clickTime);
    };
    

      
    return (<>
          <div style={{ paddingBottom: 5, float:'right' }}>
            <Space align="end" size="small">
            <Button type="link"  size="small" onClick={addProveedor} icon={<PlusOutlined style={{color:"#0e3789"}} />}/>
            <Tooltip title="Doble click en la fila para detalle" color="#0e3789" key="#0e3789" >
                <Button type="link"  size="small" icon={<QuestionOutlined style={{color:"#0e3789"}} />}/>
             </Tooltip>
            </Space>
            </div>
            
        <Table
            dataSource={tableProveedor}
            columns={filteredMenuItems}
            size="small"
            className="custom-table"
            pagination={{ showSizeChanger: true, showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} registros` }}
            loading={loading}
            onRow={(record) => ({
                onClick: () => handleClick(record),
            })}
            bordered

        />
       
    
        <ProveedorForm openModal={visible} closeModal={closeModal} titleModal={title} datosForm={initialValues} fetchData={fetchProveedores} />
    </>
    );
}
export default ProveedorTable;