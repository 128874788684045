/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input, Space, Table, Tooltip, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getProducts, getCategories } from "../../data/api";
import { PlusOutlined, QuestionOutlined, ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import ProductForm from "./ProductForm";
import { formatNumber } from "../../util";
import { useRef } from "react";
import Highlighter from "react-highlight-words";

const ProductTable = ({onClickRowTable, productsTable, setProductsTable, loading, setLoading}) => {
    //const [productsTable, setProductsTable] = useState([]);
    const [categories, setCategories] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    //const [loading, setLoading] = useState([]);
    const [title, setTitle] = useState(false);
    const [visible, setVisible] = useState(false);
    const dataToken = useSelector(state => state.data.data);
    const valResponsive = useSelector(state => state.responsive);

    useEffect(() => {

        fetchProducts();
        fetchCategory();
    }, []);

    /** Inicio */
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Input
            ref={searchInput}
            placeholder={`Buscar ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: 'block',
            }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
              }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{
                width: 90,
              }}
            >
              Limpiar
            </Button>
           
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? '#1677ff' : undefined,
          }}
        />
      ),
      onFilter: (value, record) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    });
    /** Fin */

    const fetchCategory = async () => {

        setLoading(true);
        try {
            const response = await getCategories(dataToken.local);
            if (response.data.code === 0 && response.data.counts > 0) {
                setCategories(response);
            } else {
                setCategories([]);
            }

        } catch (error) {
            message.error(error.message);
        }
        setLoading(false);
    }

    const fetchProducts = async () => {

        setLoading(true);
        try {
            const response = await getProducts(dataToken.local);
            if (response.data.code === 0 && response.data.counts > 0) {
                const formattedData = response.data.results.map((item, index) => ({
                    ...item,
                    key: index,
                }));
                setProductsTable(formattedData);
            } else {
                setProductsTable([]);
            }

        } catch (error) {
            message.error(error.message);
        }
        setLoading(false);
    }
    
    const addProduct = () => {

        setVisible(true);
        setTitle('Nuevo Producto');
        setInitialValues({ local: dataToken.local });
    }
    const closeModal = () => {
        setVisible(false);
    }



    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id,
            defaultSortOrder: 'descend',
            mobile: false
        },
        {
            title: 'Categoria',
            dataIndex: 'categorianombre',
            key: 'categorianombre',
            mobile: true,
            ...getColumnSearchProps('categorianombre')
        },
        {
            title: 'Titulo',
            dataIndex: 'titulo',
            key: 'titulo',
            mobile: true,
            ...getColumnSearchProps('titulo')
        },
        {
            title: 'Foto',
            dataIndex: 'foto',
            key: 'foto',
            mobile: false,
            render: (text, record) => (
                <span>{record.foto  ? 'Con Foto' : 'Sin Foto'}</span>
              )

        },
        {
            title: 'Costo',
            dataIndex: 'costo',
            key: 'costo',
            mobile: false,
            render: (text, record) => (
                <span>{formatNumber(record.costo)}</span>
              )
        },
        {
            title: 'Descuento',
            dataIndex: 'descuento',
            key: 'descuento',
            mobile: false,
            render: (text, record) => (
                <span>{record.descuento} %</span>
              )
        }, 
        {
            title: 'Venta',
            dataIndex: 'venta',
            key: 'venta',
            mobile: true,
            render: (text, record) => (
                <span>{formatNumber(record.venta)}</span>
              )
        },
        {
            title: 'Stock',
            dataIndex: 'orden',
            key: 'orden',
            mobile: false,
            render: (text, record) => (
                <span>{record.orden === 0 ? <Typography.Text type="danger">{text}</Typography.Text> : <Typography.Text >{text}</Typography.Text>}</span>
            ),
            ...getColumnSearchProps('orden')
        },
        {
            title: 'Inventario',
            dataIndex: 'inventario',
            key: 'inventario',
            mobile: false
        },
        {
            title: 'Public',
            dataIndex: 'activo',
            key: 'activo',
            mobile: true,
            render: (text, record) => (
                <span>{record.activo === 1 ? 'Si' : 'No'}</span>
              )

        },
    ];
    const filteredMenuItems = columns.filter(item => {
        if (valResponsive) {
            if (item.mobile) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }

    });

    

      const [lastClickTime, setLastClickTime] = useState(0);

    const handleClick = (record) => {
        
        setInitialValues(record);
       //onClickRowTable(record);
        const clickTime = new Date().getTime();
        const doubleClickDelay = 300;

        if (clickTime - lastClickTime < doubleClickDelay) {


            setVisible(true);
            setTitle('Actualizar Producto');
        }

        setLastClickTime(clickTime);
    };

    const onSelectChange =(records,record)=>{
        
        onClickRowTable(record[0]);
    }
    return (
        <>
            <div style={{ paddingBottom: 5, float:'right' }}>
                <Space align="end">
                <Button type="link"  size="small" onClick={fetchProducts} icon={<ReloadOutlined style={{color:"#0e3789"}} />}/>
            <Button type="link"  size="small" onClick={addProduct} icon={<PlusOutlined style={{color:"#0e3789"}} />}/>
            <Tooltip title="Doble click en la fila para detalle" color="#0e3789" key="#0e3789" >
                    <Button type="link" size="small" icon={<QuestionOutlined style={{ color: "#0e3789" }} />} />
                </Tooltip>
            </Space>
               
            
        </div>
            <Table dataSource={productsTable}
                columns={filteredMenuItems}
                size="small"
                className="custom-table"
                pageSize={20}
                pagination={{ showSizeChanger: true, showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} registros` }}
                loading={loading}
                rowSelection={{type:'radio',initialValues,onChange: onSelectChange,}}
                onRow={(record) => ({
                    onClick: () => handleClick(record),
                })}
            />
            
    <ProductForm openModal={visible} closeModal={closeModal} titleModal={title} datosForm={initialValues} fetchData={fetchProducts} categories={categories} />


        </>
    );
};
export default ProductTable;