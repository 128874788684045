/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button,  Space, Table, Tooltip, message } from "antd";
import { PlusOutlined, QuestionOutlined } from "@ant-design/icons";
import { getCategories } from "../../data/api";
import { useSelector } from "react-redux";

import CategoriaForm from "./CategoriaForm";



const CategoriaTable = () => {
    
    const [tableCategoria, setTableCategoria] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [title, setTitle] = useState(false);
    const dataToken = useSelector(state => state.data.data);
   
    useEffect(() => {
        fetchCategoria();
        //console.log('useEffect Categoria');
    }, []);

    const fetchCategoria = async () => {
        setLoading(true);
        try {
            const response = await getCategories(dataToken.local);
            if (response.data.code === 0 && response.data.counts > 0) {
                
                setTableCategoria(response.data.results);
            } else {
                setTableCategoria([]);
            }

        } catch (error) {
            message.error(error.message);
        }
        setLoading(false);
    };
    const addCategoria = () => {
        
        setVisible(true);
        setTitle('Nuevo Categoria');
        setInitialValues({ local: dataToken.local });
    }
    const closeModal = () => {
        setVisible(false);
    }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id,
            defaultSortOrder: 'descend',
        },
        {
            title: 'Nombre',
            dataIndex: 'nombre',
            key: 'nombre',
        },
        {
            title: 'Extra',
            dataIndex: 'extra',
            key: 'extra',
        }
    ];

   

    const [lastClickTime, setLastClickTime] = useState(0);

    const handleClick = (record) => {
        setInitialValues(record);
        const clickTime = new Date().getTime();
        const doubleClickDelay = 300;

        if (clickTime - lastClickTime < doubleClickDelay) {


            setVisible(true);
            setTitle('Actualizar Categoria');
        }

        setLastClickTime(clickTime);
    };
    

      
    return (<>
          <div style={{ paddingBottom: 5, float:'right' }}>
            <Space align="end" size="small">
            <Button type="link"  size="small" onClick={addCategoria} icon={<PlusOutlined style={{color:"#0e3789"}} />}/>
            <Tooltip title="Doble click en la fila para detalle" color="#0e3789" key="#0e3789" >
                <Button type="link"  size="small" icon={<QuestionOutlined style={{color:"#0e3789"}} />}/>
             </Tooltip>
            </Space>
            </div>
            
        <Table
            dataSource={tableCategoria}
            columns={columns}
            size="small"
            className="custom-table"
            pagination={{ showSizeChanger: true, showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} registros` }}
            loading={loading}
            onRow={(record) => ({
                onClick: () => handleClick(record),
            })}
            bordered

        />
       
    
        <CategoriaForm openModal={visible} closeModal={closeModal} titleModal={title} datosForm={initialValues} fetchData={fetchCategoria} />
    </>
    );
}
export default CategoriaTable;