import { LockOutlined } from "@ant-design/icons";
import { Button, Input, Space, Typography, message } from "antd";
import { useState } from "react";
import { changePassPost } from "../../data/api";
import { CHANGE_PASS_OK } from "../../constans";

const ChangePassword = ({ idUsuario, nombres, setIsPasswordChange }) => {
    const [pass, setPass] = useState('');

    const handleChangePassword = async () => {
        const values = {
            id: idUsuario,
            password: pass
        };
        try {
            const response = await changePassPost(values);
            if (response.data.code === 0) {
                if (response.data.result.code === 0) {
                    message.success(CHANGE_PASS_OK);
                    setIsPasswordChange(false);
                    setPass('');
                } else {
                    message.error(response.data.message);
                }
            } else {
                message.error(response.data.message);
            }

        } catch (error) {

            message.error(error.message);
        }

    }
    const handlePass = (e) => {
        setPass(e.target.value);
    }
    return (<div>
        <Space direction="vertical"
            size="middle"
            style={{
                display: 'flex',
            }}>
            <Typography.Text level={5} type="success" >Nueva Clave {nombres} </Typography.Text>
            <Input.Password value={pass} onChange={handlePass} placeholder="Ingresar la nueva clave" />
            <Button type="primary" block onClick={handleChangePassword}><LockOutlined /> Cambiar  Contraseña</Button>
        </Space>
    </div>);
};
export default ChangePassword;