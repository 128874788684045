/* eslint-disable react-hooks/exhaustive-deps */
import { Table, message } from "antd";
import { useEffect,  useState } from "react";
import { useSelector } from "react-redux";
import { formatNumber } from "../../util";
import { getCustomers, getPedidoClienteDebe } from "../../data/api";
import PedidoForm from "./PedidoForm";



const PedidoDeben = ({ loading, pedidosTable, fetchPedidoDebe, tab }) => {
    const [clientes, setClientes] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [filterClient, setFilterClient] = useState([]);
    //const [dataFinal, setDataFinal] = useState([]);
    


    const valResponsive = useSelector(state => state.responsive);
    const dataToken = useSelector(state => state.data.data);

    
  
    useEffect(() => {


        fetchClientes();
        fetchClienteDebe();
      
       

    }, []);

    /*useEffect(() => {
        if(pedidosTable.length > 0){
            
        if(dataFinal.length > 0 && pedidosTable.length === dataFinal.length){
            const dataWithoutTotal = dataFinal.filter(record => record.clientealias!=='');
        setDataFinal(getFilteredDataWithTotal(dataWithoutTotal));
        }else{
            setDataFinal(getFilteredDataWithTotal(pedidosTable));
        }
        }else{
            setDataFinal([]);
        }

    }, [pedidosTable]);*/

    const closeModal = () => {
        setOpenModal(false);
    }

   /* const calculateTotal = (data) => {
        return data.reduce((acc, record) => acc + (record.venta || 0), 0);
    };
    const calculateTotalPorPagar = (data) => {
        return data.reduce((acc, record) => acc + (record.porpagar || 0), 0);
    };*/

   /* const getFilteredDataWithTotal = (filteredData) => {
       // return filteredData;
     //  console.log('filteredData',filteredData);
        const total = calculateTotal(filteredData);
        const totalporpagar = calculateTotalPorPagar(filteredData);
        const totalRow = { id: '',key:-1, clientealias:'',descripcion: '',horafecha:'Total',parcial:'',delivery:'',descuento:'', venta: total,porpagar:totalporpagar,estado:'',extra:'' };
        return [...filteredData, totalRow];
    };*/

    // Manejar el filtrado
   /* const handleChange = (pagination, filters, sorter, extra) => {
        //setFilteredData(extra.currentDataSource);
       
        if(filters.clientealias == null){
       
        setDataFinal(getFilteredDataWithTotal(pedidosTable));
        }else{

        const dataWithoutTotal = extra.currentDataSource.filter(record => record.clientealias!=='');
        
        // Recalcular el total con los datos filtrados
        setDataFinal(getFilteredDataWithTotal(dataWithoutTotal));
    }

    };*/


    const fetchClienteDebe = async () => {
        const response = await getPedidoClienteDebe(dataToken.local);
        if (response.data.code === 0 && response.data.counts > 0) {
            setFilterClient(response.data.results.map(element => ({
                text: element.alias,
                value: element.alias
            })));
        } else {
            setFilterClient([]);
        }
        //console.log(response);
    }

    const fetchClientes = async () => {

        try {

            const response = await getCustomers(dataToken.local);
            if (response.data.code === 0 && response.data.counts > 0) {
                setClientes(response);
            } else {
                setClientes([]);
            }
        } catch (error) {
            message.error(error.message);
        }


    }


    const columns = [
        {
            title: 'Nro',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id,
            defaultSortOrder: 'descend',
            mobile: true,
            width: 60
        },
        {
            title: 'Cliente',
            dataIndex: 'clientealias',
            key: 'clientealias',
            mobile: true,
            width: 100,
            filters: filterClient,
            onFilter: (value, record) => {
                // Incluye la fila total independientemente del filtro
                if (record.clientealias==='') return true;
                return record.clientealias.indexOf(value) === 0;
            },
        },
        {
            title: 'Detalle',
            dataIndex: 'descripcion',
            key: 'descripcion',
            mobile: false,
            ellipsis: true,
            render: (text, record) => (
                <span style={{ color: "#2336b0" }}>{record.descripcion}</span>
            ),

        },
        {
            title: 'Hora',
            dataIndex: 'horafecha',
            key: 'horafecha',
            mobile: true,
            ellipsis: true,
            width: 100

        },
        {
            title: 'Adelanto',
            dataIndex: 'parcial',
            key: 'parcial',
            mobile: false,
            render: (text, record) => (
                <span>{formatNumber(record.parcial)}</span>
            ),
            width: 60
        },
        {
            title: 'Delivery',
            dataIndex: 'delivery',
            key: 'delivery',
            mobile: false,
            render: (text, record) => (
                <span>{formatNumber(record.delivery)}</span>
            ),
            width: 60
        },
        {
            title: 'Descuen',
            dataIndex: 'descuento',
            key: 'descuento',
            mobile: false,
            render: (text, record) => (
                <span>{formatNumber(record.descuento)}</span>
            ),
            width: 60
        },
        {
            title: 'Venta',
            dataIndex: 'venta',
            key: 'venta',
            mobile: false,
            render: (text, record) => (
                <span style={{ color: "#2e42c7" }}>{formatNumber(record.venta)}</span>
            ),
            width: 60
        },
        {
            title: 'PorPagar',
            dataIndex: 'porpagar',
            key: 'porpagar',
            mobile: true,
            render: (text, record) => (
                <span style={{ color: "#6e2308" }}>{formatNumber(record.venta + record.delivery - record.parcial)}</span>
            ),
            width: 60
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
            mobile: false,
            width: 100,


        },
        {
            title: 'Obs',
            dataIndex: 'extra',
            key: 'extra',
            mobile: false,
            ellipsis: true,
            width: 100

        },


    ];
    const filteredMenuItems = columns.filter(item => {
        if (valResponsive) {
            if (item.mobile) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }

    });

    const [lastClickTime, setLastClickTime] = useState(0);

    const handleClick = (record) => {

        setInitialValues(record);
        //setInitialValues(record);
        //onClickRowTable(record);
        const clickTime = new Date().getTime();
        const doubleClickDelay = 300;

        if (clickTime - lastClickTime < doubleClickDelay) {
            //console.log('ENTRO');
            setOpenModal(true);
            //setVisible(true);
            //setTitle('Actualizar Producto');
        }

        setLastClickTime(clickTime);
    };




    return (

        <>
            <Table  dataSource={pedidosTable}
                columns={filteredMenuItems}
                size="small"
                className="custom-table"
                
                //pageSize={20}
                //pagination={{ showSizeChanger: true, showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} registros` }}
                pagination={false}
                loading={loading}
               //onChange={handleChange}
                expandable={{
                    expandedRowRender: (record) => (
                        <p
                            style={{
                                margin: 0,
                                color: '#2336b0'
                            }}
                        >
                            {record.descripcion}
                        </p>
                    )
                }}

                onRow={(record) => ({
                    onClick: () => handleClick(record),
                })}
                rowClassName={(record) => record.horafecha === 'Total' ? 'custom-bold-row' : ''}
            />
            <PedidoForm openModal={openModal} closeModal={closeModal} datosForm={initialValues} fetchData={fetchPedidoDebe} clientes={clientes} tab={tab} />
        </>
    );
};
export default PedidoDeben;