import React, { useState } from 'react';
import VentaFind from './VentaFind';
import Ganancia from './Ganancia';
import { useSelector } from 'react-redux';

import dayjs from 'dayjs';
import { message } from 'antd';


function Estadistica() {
  const dataToken = useSelector(state => state.data.data);
const[begin,setBegin] = useState(dayjs().startOf('month').format('YYYY-MM-DD'));
const[end,setEnd] = useState(dayjs().endOf('month').format('YYYY-MM-DD'));
const mostrar = (a,b) => {
  if(a===null){
    message.error('Por favor ingrese la fecha');
    return;
}

  setBegin(a)
  setEnd(b);

}

      return (
        <>
        <VentaFind mostrar={mostrar}/>
        <Ganancia local={dataToken.local} begin={begin} end={end} />
        </>
  );
};

export default Estadistica;
