import React, { useEffect, useState } from "react";
import { Tabs, message, } from "antd";
import { CoffeeOutlined, AlertOutlined, DollarOutlined } from "@ant-design/icons";

//import { useSelector } from "react-redux";
import PedidoEnCurso from "./PedidoEnCurso";
import PedidoDeben from "./PedidoDeben";
import PedidoNuevo from "./PedidoNuevo";
import { getPedidosDebe, getPedidosEnCurso, getPedidosPagado } from "../../data/api";

import { jwtDecode } from "jwt-decode";
import PedidoPagado from "./PedidoPagado";
import dayjs from 'dayjs';
import { formatFecha } from "../../util";

const Pedido = () => {

  const [pedidosTable, setPedidosTable] = useState([]);
  const [pedidosDebeTable, setPedidosDebeTable] = useState([]);
  const [pedidosPagadoTable, setPedidosPagadoTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [descripcion, setDescripcion] = useState('');
  const [begin, setBegin] = useState('');
  const [end, setEnd] = useState('');


  // const[begin,setBegin] = useState(dayjs().startOf('month').format('YYYY-MM-DD'));
  // const[end,setEnd] = useState(dayjs().endOf('month').format('YYYY-MM-DD'));


  useEffect(() => {



    fetchPedidoEnCurso();
  }, []);

  const fetchPedidoEnCurso = async () => {


    setLoading(true);
    try {
      let dataToken = jwtDecode(localStorage.getItem('tk'));

      const response = await getPedidosEnCurso(dataToken.data.local);
      if (response.data.code === 0 && response.data.counts > 0) {

        setPedidosTable(response.data.results);
      } else {
        setPedidosTable([]);
      }

    } catch (error) {
      message.error(error.message);
    }
    setLoading(false);

  }
  const fetchPedidoDebe = async () => {


    setLoading(true);
    try {
      let dataToken = jwtDecode(localStorage.getItem('tk'));

      const response = await getPedidosDebe(dataToken.data.local);

      if (response.data.code === 0 && response.data.counts > 0) {
       

        setPedidosDebeTable(response.data.results);
      } else {
        setPedidosDebeTable([]);
      }

    } catch (error) {
      message.error(error.message);
    }
    setLoading(false);

  }
  const mostrar = (a, b) => {
    setBegin(a);
    setEnd(b);

    fetchPedidoPagado(a, b);
    setDescripcion(formatFecha(a, b));

  }
  const fetchPedidoPagado = async (a, b) => {
    /*console.log('a', a);
    console.log('b', b);
    console.log('begin', begin);
    console.log('end', end);*/
    if (typeof a === 'undefined' && begin === '') {
      a = dayjs().startOf('month').format('YYYY-MM-DD');
      b = dayjs().endOf('month').format('YYYY-MM-DD');
    } else {
      if (begin !== '' && a === begin) {
        a = begin;
        b = end;
      }
      if (typeof a === 'undefined'){
        a = begin;
        b = end;
      }
    }
   // console.log('a final', a);
   // console.log('b final', b);
    setDescripcion(formatFecha(a, b));

    setLoading(true);
    try {
      let dataToken = jwtDecode(localStorage.getItem('tk'));

      const response = await getPedidosPagado(dataToken.data.local, a, b);

      if (response.data.code === 0 && response.data.counts > 0) {
        setPedidosPagadoTable(response.data.results);
      } else {
        setPedidosPagadoTable([]);
      }

    } catch (error) {
      message.error(error.message);
    }
    setLoading(false);

  }
  const itemsTab = [
    {
      key: 1,
      label: 'En Curso',
      children: <PedidoEnCurso loading={loading} pedidosTable={pedidosTable} fetchPedidoEnCurso={fetchPedidoEnCurso} tab="curso" />,
      icon: <CoffeeOutlined style={{ color: "#0e3789" }} />,
    },
    {
      key: 2,
      label: 'Deben',
      children: <PedidoDeben loading={loading} pedidosTable={pedidosDebeTable} fetchPedidoDebe={fetchPedidoDebe} tab="deben" />,
      icon: <AlertOutlined style={{ color: "#C0412A" }} />,
    },
    {
      key: 3,
      label: 'Pagados',
      children: <PedidoPagado loading={loading} pedidosTable={pedidosPagadoTable} fetchPedidoPagado={fetchPedidoPagado} tab="pagado" descripcion={descripcion} mostrar={mostrar} />,
      icon: <DollarOutlined style={{ color: "#348532" }} />,
    },

  ];
  const handleChangeTab = (activeKey) => {

    if (activeKey === 1) {
      fetchPedidoEnCurso();
    }
    if (activeKey === 2) {
      fetchPedidoDebe();
    }
    if (activeKey === 3) {
      fetchPedidoPagado();
    }

  };

  /*const fetchProductInsumo = async () => {
    setLoading(true);
    try {
      const response = await getInsumos(rowTable.id);
      if (response.data.code === 0 && response.data.counts > 0) {
        const formattedData = response.data.results.map((item, index) => ({
          ...item,
          key: index,
        }));
        setInsumoTable(formattedData);
      } else {
        setInsumoTable([]);
      }
 
    } catch (error) {
      message.error(error.message);
    }
    setLoading(false);
  }*/




  return (<>
    <PedidoNuevo fetchPedidoEnCurso={fetchPedidoEnCurso} />
    <Tabs
      defaultActiveKey="1"
      size='small'
      style={{
        marginBottom: 24,

      }}
      items={itemsTab}
      onChange={handleChangeTab}
    />
  </>);
}
export default Pedido;