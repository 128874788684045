import React, { useState } from "react";
import { Tabs, message } from "antd";
import { ProductOutlined, FireOutlined, ReadOutlined } from "@ant-design/icons";
import ProductTable from "./ProductTable";
import ProductInsumo from "./ProductInsumo";
import ProductPrepare from "./ProductPrepare";
import { getInsumos, getProducts, getElaboras } from "../../data/api";
import { useSelector } from "react-redux";

const Product = () => {
    const[rowTable,setRowTable]=useState([]);
    const[insumoTable,setInsumoTable]=useState([]);
    const[productsTable,setProductsTable]=useState([]);
    const[prepareText,setPrepareText]=useState([]);
    const[loading,setLoading]=useState([]);
    const dataToken = useSelector(state => state.data.data);
   function onClickRowTable (record) {
    setRowTable(record);

   }
    const itemsTab = [
        {
            key: 1,
            label: 'Productos',
            children: <ProductTable onClickRowTable={onClickRowTable} productsTable={productsTable} setProductsTable={setProductsTable} loading={loading} setLoading={setLoading} />,
            icon: <ProductOutlined style={{color:"#0e3789"}} />,
        },
        {
            key: 2,
            label: 'Insumos',
            children: <ProductInsumo rowTable={rowTable} insumoTable={insumoTable} loading={loading} setInsumoTable={setInsumoTable} setLoading={setLoading} />,
            icon: <FireOutlined  style={{color:"#C0412A"}} />,
        },
        {
            key: 3,
            label: 'Preparación',
            children: <ProductPrepare rowTable={rowTable} prepareText={prepareText} loading={loading} setPrepareText={setPrepareText} setLoading={setLoading}  />,
            icon: <ReadOutlined style={{color:"#009017"}}/>,
        },
    ];
    const handleChangeTab = (activeKey) => {
        
        if(activeKey===2){
            fetchProductInsumo();
        }
        if(activeKey === 1){
            fetchProducts();
        }
        if(activeKey===3){
            fetchElaboras();
        }
      };

      const fetchProductInsumo = async () => {
        setLoading(true);
        try {
          const response = await getInsumos(rowTable.id);
          if (response.data.code === 0 && response.data.counts > 0) {
            const formattedData = response.data.results.map((item, index) => ({
              ...item,
              key: index,
            }));
            setInsumoTable(formattedData);
          } else {
            setInsumoTable([]);
          }
    
        } catch (error) {
          message.error(error.message);
        }
        setLoading(false);
      }
      const fetchProducts = async () => {
        
        setLoading(true);
        try {
            const response = await getProducts(dataToken.local);
            if (response.data.code === 0 && response.data.counts > 0) {
                const formattedData = response.data.results.map((item, index) => ({
                    ...item,
                    key: index,
                }));
                setProductsTable(formattedData);
            } else {
                setProductsTable([]);
            }

        } catch (error) {
            message.error(error.message);
        }
        setLoading(false);
    }

    const fetchElaboras = async () => {
        setLoading(true);
        
        try {
            const response = await getElaboras(rowTable.id);
           
            if (response.data.code === 0 && response.data.counts > 0) {
                
                setPrepareText(response.data.results[0]);

            } else {
                setPrepareText({id:'',producto:rowTable.id});
            }

        } catch (error) {
            message.error(error.message);
        }
        setLoading(false);
    }

    return (<Tabs
        defaultActiveKey="1"
        size='small'
        style={{
            marginBottom: 24,

        }}
        items={itemsTab}
        onChange={handleChangeTab}
    />);
}
export default Product;