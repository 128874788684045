import React from 'react';
import { Card, Space } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

function ProductCard({ item, cuenta, resta, quantities, setQuantities }) {
    //const [quantities, setQuantities] = useState({});
    

    const handleIncrement = () => {
       
        if (item.inventario === "SI") {
            if (item.orden >= (quantities[item.id] + 1) || typeof quantities[item.id]=== 'undefined' ) {
                setQuantities(prevQuantities => ({
                    ...prevQuantities,
                    [item.id]: (prevQuantities[item.id] || 0) + 1
                }));
                cuenta();
            }
        } else {
            setQuantities(prevQuantities => ({
                ...prevQuantities,
                [item.id]: (prevQuantities[item.id] || 0) + 1
            }));
            cuenta();
        }
    };

    const handleDecrement = () => {
        if (quantities[item.id] > 0) {
            setQuantities(prevQuantities => ({
                ...prevQuantities,
                [item.id]: prevQuantities[item.id] - 1

            }));
            resta();
        }
    };

    return (
        <Card
            hoverable
            cover={<img alt="example" src={`https://irest.cl/img-mercu/${item.foto}`} width={100} height={80} onClick={handleIncrement} />}>

            <span style={{ marginTop: -10, fontSize: 12 }}>{item.titulo} {item.inventario === "SI" ? <span>Disponible ({item.orden})</span> : null}</span>
            <Space align="center">

                <MinusOutlined onClick={handleDecrement} style={{ fontSize: 10, color: "#0000ff", cursor: 'pointer' }} />
                {quantities[item.id] || 0}
                <PlusOutlined onClick={handleIncrement} style={{ fontSize: 10, color: "#0000ff", cursor: 'pointer' }} />
            </Space>
        </Card>
    );
}

export default ProductCard;
