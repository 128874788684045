import React from "react";
import { Tabs } from "antd";
import {ControlOutlined } from "@ant-design/icons";
import RegularizaGrid from "./RegularizaGrid";


const Regulariza = () => {
    const itemsTab = [
        {
            key: 1,
            label: 'Regularizar Costo Pedido',
            children: <RegularizaGrid />,
            icon: <ControlOutlined style={{ color: "black" }} />,
        },
    ];
    return (<Tabs
        defaultActiveKey="1"
        size='small'
        style={{
            marginBottom: 24,

        }}
        items={itemsTab}
    />);
}
export default Regulariza;