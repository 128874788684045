/* eslint-disable react-hooks/exhaustive-deps */

import { CaretDownOutlined, CrownOutlined,  DollarOutlined, LikeOutlined,  MehOutlined, ProductOutlined,  TruckOutlined } from "@ant-design/icons";
import { Avatar, Card, Col, Flex, Progress, Row, Table, message } from "antd";
import Meta from "antd/es/card/Meta";
import CardGasto from "../gasto/CardGasto";
import { useEffect, useState } from "react";
import { getClientesPremium, getEstadistica2, getLoMasVendido } from "../../data/api";
import { formatFecha, formatNumber } from "../../util";



const Ganancia = ({local,begin,end}) => {
    const[dataStatics,setDataStatics] = useState({});
    const[lomasvendido,setLomasvendido] = useState([]);
    const[clientesPremium,setClientesPremium] = useState([]);
    const[descripcion, setDescripcion] = useState('');
    useEffect(()=>{
        fetchStatics();
        fetchLoMasVendido();
        fetchClientesPremium();
        setDescripcion(formatFecha(begin,end));
    },[begin,end]);

    const fetchStatics = async () => {

        try {
            const response = await getEstadistica2(local,begin,end);
            if (response.data.code === 0 && response.data.counts > 0) {
                setDataStatics(response.data.results[0]);
                
            } else {
                setDataStatics({});
            }

        } catch (error) {
            message.error(error.message);
        }

    };
    const fetchLoMasVendido = async () => {

        try {
            const response = await getLoMasVendido(local,begin,end,10);
            if (response.data.code === 0 && response.data.counts > 0) {
                const formattedData = response.data.results.map((item, index) => ({
                    ...item,
                    key: index
                }));
                setLomasvendido(formattedData);
            } else {
                setLomasvendido([]);
            }

        } catch (error) {
            message.error(error.message);
        }

    };
    const fetchClientesPremium = async () => {

        try {
            const response = await getClientesPremium(local,begin,end,15);
            if (response.data.code === 0 && response.data.counts > 0) {
                const formattedData = response.data.results.map((item, index) => ({
                    ...item,
                    key: index
                }));
                setClientesPremium(formattedData);
            } else {
                setClientesPremium([]);
            }

        } catch (error) {
            message.error(error.message);
        }

    };
    const dataColumns = [
        {
            title: '',
            dataIndex: 'nombre',
            key: 'nombre',
            width:80,
        },
        {
            title: 'Monto Total',
            dataIndex: 'monto',
            key: 'monto',
            
        },
        {
            title: '',
            dataIndex: 'act',
            key: 'act',
            
        },
    
    ];
    const dataExtra = [
        {
          
            nombre: 'Deben',
            monto: formatNumber(dataStatics.deben),
            key:1,
            act:<MehOutlined style={{color:"#ff0000", fontSize:20}} />
           
            
        },
        
        {
          
            nombre: 'Delivery',
            monto: formatNumber(dataStatics.delivery),
            key:2,
            act:<TruckOutlined style={{color:"#0000ff", fontSize:20}} />
            
        },
        {
          
            nombre: 'Descuento',
            monto:formatNumber(dataStatics.descuento),
            key:3,
            act:<CaretDownOutlined style={{color:"#ff0000", fontSize:18}} />
            
        },
       
    
    ];

      return (<>
      
      <Row gutter={[8,8]}>
      <Col xs={24} sm={24} md={24} lg={8} xl={8} >
  <Card
        style={{
          width: '100%',
          marginTop: 10,
        }}
       // loading={loading}
      >
        <Meta
          avatar={<Avatar icon={<DollarOutlined  /> }  style={{color:"white",backgroundColor: '#87d068',}}/>}
          title={<span style={{ fontSize: '13px', color:"#0e3789" }}>{descripcion} </span>}
          
        />
         <Flex
    vertical
    gap="small"
    style={{
      width: 220,
      marginTop:5
    }}
  >
    <strong><Progress percent={dataStatics.porcentaje_ganancia} status="active" format={() => formatNumber(dataStatics.ganancia)} strokeColor="#008AE0"/></strong>
    <span style={{fontSize:12, marginTop:-10}}>Ganancia</span>
    <Progress percent={100}  status="active" style={{marginTop:-10}} format={() => formatNumber(dataStatics.venta)} strokeColor="#2F6A8F"/>
    <span style={{fontSize:12, marginTop:-10}}>Venta</span>
    <Progress percent={dataStatics.porcentaje_costo}  status="active" style={{marginTop:-10}} format={() => formatNumber(dataStatics.costo)} strokeColor={"#1E7CB6"}/>
    <span style={{fontSize:12, marginTop:-10}}>Costo</span>
   
  </Flex>
      </Card>
      </Col>
      <Col xs={24} sm={24} md={24} lg={8} xl={8}  >
            <Card
        style={{
            width:'100%',
          marginTop: 10,
        }}
       // loading={loading}
       
      >
        <Table 
        
        dataSource={dataExtra}
        columns={dataColumns}
        size="small"
       
        bordered
        pagination={false}
        />
        </Card>
        </Col>

        <Col xs={24} sm={24} md={24} lg={8} xl={8}  >
            
            <Card
            title="Vendistes"
        style={{
            width:'100%',
          marginTop: 10,
          textAlign:'center',
          display: 'flex',
          justifyContent: 'center', // Centra horizontalmente
          alignItems: 'center', // Centra verticalmente
        }}
       // loading={loading}
      
      >
        <Flex gap="small" wrap >
        <Progress type="circle" status='active' percent={100} format={() => dataStatics.cantidad+' Prod'} strokeColor={"#406161"}/>
        
    
  </Flex>
       
        </Card>
        </Col>
       
     </Row>


     <Row gutter={[8,8]}>
      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
  <Card
        style={{
          width: '100%',
          marginTop: 10,
        }}
       // loading={loading}
      >
        <Meta
          avatar={<Avatar icon={<ProductOutlined  /> }  style={{color:"white",backgroundColor: '#8403fc',}}/>}
          title={<span style={{ fontSize: '14px', color:"#0e3789" }}>Lo Más Vendidos <LikeOutlined style={{fontSize:14}}/></span>}
          
        />
         <Flex
    vertical
    gap="small"
    style={{
      width: 220,
      marginTop:5
    }}
  >
    {lomasvendido?.map(item => (
        <div>
    <Progress percent={item.porcentaje} status="active" format={() => item.cantidad+' prod'} strokeColor="#8403fc"/>
    <span style={{fontSize:12, marginTop:-10}}>{item.nombreproducto}</span></div>
                            ))}
    
    
   
  </Flex>
      </Card>
      </Col>

      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
  <Card
        style={{
          width: '100%',
          marginTop: 10,
        }}
       // loading={loading}
      >
        <Meta
          avatar={<Avatar icon={<CrownOutlined  /> }  style={{color:"white",backgroundColor: '#fca103',}}/>}
          title={<span style={{ fontSize: '14px', color:"#0e3789" }}>Clientes Premium <CrownOutlined style={{fontSize:14}}/></span>}
          
        />
         <Flex
    vertical
    gap="small"
    style={{
      width: 220,
      marginTop:5
    }}
  >
    {clientesPremium?.map(item => (
        <div>
    <Progress percent={item.porcentaje} status="active" format={() => item.cantidad + ' prod'} strokeColor="#fca103"/>
    <span style={{fontSize:12, marginTop:-10}}>{item.nombrecliente}</span></div>
                            ))}
    
    
   
  </Flex>
      </Card>
      </Col>
      
      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
 <CardGasto totalGasto={dataStatics.costogasto} totalCantidad={dataStatics.cantidadgasto} descripcion={descripcion}/>
      </Col>
      </Row>
      </>); 
};
export default Ganancia;