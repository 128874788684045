/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowLeftOutlined, CheckOutlined, ClearOutlined, EditOutlined, HomeOutlined, IdcardOutlined, PlusOutlined, ReloadOutlined,  ShopOutlined, ShoppingCartOutlined, UserOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Col, DatePicker, Drawer, Form, Input, List, Modal, Result, Row, Select, Space, Spin, Table, TimePicker, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { getProductsActivos, getCustomers, pedidoPost, pedprodPost, customerPost, customerPut } from "../../data/api";
import { jwtDecode } from "jwt-decode";

//import moment, { now } from 'moment';
import ProductCard from "./ProductCard";
import { formatNumber } from "../../util";
import dayjs from 'dayjs';
import { CREATE_NOK, CREATE_OK, UPDATE_NOK, UPDATE_OK } from "../../constans";

const { Search } = Input;

//import { useSelector } from "react-redux";



const PedidoNuevo = ({ fetchPedidoEnCurso }) => {
    const [open, setOpen] = useState(false);
    const [openClient, setOpenClient] = useState(false);
    const [loadingFormCliente, setLoadingFormCliente] = useState(false);

    const [openHijo, setOpenHijo] = useState(false);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [productActivos, setProductActivos] = useState([]);
    const [productActivosOrigin, setProductActivosOrigin] = useState([]);
    const [contador, setContador] = useState(0);
    const [quantities, setQuantities] = useState({});
    const [productsSelected, setProductsSelected] = useState([]);
    const [customerSelected, setCustomerSelected] = useState([]);
    //const [dataCustomerSelected, setDataCustomerSelected] = useState([]);
    const [isOk, setIsOk] = useState(false);
    const [loadTwoDrawer, setLoadTwoDrawer] = useState(false);
    const [idCliente, setIdClente] = useState('');
    const [maxIdCliente, setMaxIdCliente] = useState(false);
    const [clienteSeleccionado, setClienteSeleccionado] = useState({ id: '' });

    const [datePickerData, setDataPickerData] = useState(dayjs().format('DD/MM/YYYY'));
    const [timePickerData, setTimePickerData] = useState(dayjs().add(2, 'Hour').format('HH:mm'));
    const [ventaTotal, setVentaTotal] = useState(0);
    const [costoTotal, setCostoTotal] = useState(0);
    const [descuentoTotal, setDescuentoTotal] = useState(0);
    const[textoBuscar,setTextoBuscar]=useState('');

    const [formCliente] = Form.useForm();

    const columns = [
        {
            title: 'Cant',
            dataIndex: 'cantidad',
            key: 'cantidad'
        },
        {
            title: 'Producto',
            dataIndex: 'titulo',
            key: 'titulo',
        },
        {
            title: 'Descu',
            dataIndex: 'descuentofinal',
            key: 'descuentofinal',
            render: (text, record) => (
                <span>{formatNumber(record.descuentofinal)}</span>
            )
        },
        {
            title: 'Precio C/U',
            dataIndex: 'ventafinal',
            key: 'ventafinal',
            render: (text, record) => (
                <span>{formatNumber(record.ventafinal)}</span>
            )
        }
    ];
    
    const handleSearchChange = (e) => {
        
        const searchValue = e.target.value;
        
        if(searchValue !== ''){
            setTextoBuscar(searchValue);
            const filtered = productActivos.filter(product => product.titulo.toLowerCase().includes(searchValue.toLowerCase()));
            setProductActivos(filtered);
        }else{
            setTextoBuscar('');
            setProductActivos(productActivosOrigin);
        }
        
      };

    const closeHijo = () => {
        setOpenHijo(false);
    }
    const cuenta = () => {
        let cnt = contador + 1;
        setContador(cnt);
    }
    const resta = () => {
        let cnt = contador - 1;
        if (cnt >= 0) {
            setContador(cnt);
        }
    }

    useEffect(() => {
        fetchProductsActivos();
    }, []);

    useEffect(() => {


        const filtro = customerSelected.filter(item => item.id === idCliente);
        setClienteSeleccionado(filtro[0]);
    }, [idCliente]);

    const showDrawer = () => {
        setVisible(true);
        cleanQuantity();
        setClienteSeleccionado({ id: '' });
        setTextoBuscar('');
        setProductActivos(productActivosOrigin);
    };

    const onClose = () => {
        setVisible(false);
    };
    const cleanQuantity = () => {
        setQuantities({});
        setContador(0);
        setTextoBuscar('');
        setProductActivos(productActivosOrigin);
    }

    const handleNext = () => {

        if (contador > 0) {
            setOpenHijo(true);
            const combinedArray = productActivosOrigin.map((item, index) => ({
                ...item,
                cantidad: quantities[item.id] || 0,
                key: index
            })).filter(item => item.cantidad > 0);
            const totalPrecio = combinedArray.reduce((acc, item) => acc + item.cantidad * item.ventafinal, 0);
            const totalCosto = combinedArray.reduce((acc, item) => acc + item.cantidad * item.costo, 0);
            const totalDescuento = combinedArray.reduce((acc, item) => acc + item.cantidad * item.descuentofinal, 0);
            setVentaTotal(totalPrecio);
            setCostoTotal(totalCosto);
            setDescuentoTotal(totalDescuento);
            const newData = [...combinedArray, { cantidad: 'Total', titulo: '', descuentofinal: totalDescuento, ventafinal: totalPrecio, key: -1 }];

            setProductsSelected(newData);

            fecthCustomer();
        }
    }

    const fetchProductsActivos = async () => {
        setLoading(true);
        try {
            let decodedToken = jwtDecode(localStorage.getItem('tk'));
            const response = await getProductsActivos(decodedToken.data.local);

            if (response.data.code === 0 && response.data.counts > 0) {

                const productsWithQuantity = response.data.results.map(product => ({
                    ...product,
                    cantidad: 0  // Establecer la cantidad inicial como 0
                }));
                setProductActivos(productsWithQuantity);
                setProductActivosOrigin(productsWithQuantity);

            } else {
                setProductActivos([]);
                setProductActivosOrigin([]);
            }



        } catch (error) {
            message.error(error.message);
        }
        setLoading(false);

    }

    const fecthCustomer = async () => {

        try {
            let dataToken = jwtDecode(localStorage.getItem('tk'));
            const response = await getCustomers(dataToken.data.local);

            if (response.data.code === 0 && response.data.counts > 0) {
                const formattedData = response.data.results.map((item, index) => ({
                    ...item,
                    value: item.id,
                    label: item.nombres,
                    key: index
                }));
                setCustomerSelected(formattedData);
                if (maxIdCliente) {
                    let idMax = formattedData.reduce((max, obj) => (obj.id > max ? obj.id : max), formattedData[0].id);
                    setIdClente(idMax);
                } else {

                    const filtro = formattedData.filter(item => item.id === idCliente);
                    setClienteSeleccionado(filtro[0]);
                }
            } else {
                setCustomerSelected([{ value: 0, label: '' }]);
            }


        } catch (error) {
            message.error(error.message);
        }
    };

    function closeAllVentanas() {
        setOpen(false);
        setOpenHijo(false);
        setVisible(false);
    }
    function closeModal() {
        setOpenClient(false);
    }

    const handleReserve = async () => {



        //console.log('idCliente',idCliente);
        if (idCliente === '') {
            message.error('Debe Seleccionar el Cliente');
            return;
        }
        setLoadTwoDrawer(true);

        try {
            let dataToken = jwtDecode(localStorage.getItem('tk'));
            const values = {
                cliente: idCliente,
                fecha: datePickerData,
                hora: timePickerData,
                costo: costoTotal,
                descuento: descuentoTotal,
                venta: ventaTotal,
                usuario: dataToken.data.username,
                local: dataToken.data.local,
            };

            const response = await pedidoPost(values);
            const lastId = response.data.result.lastId;




            if (response.data.code === 0) {
                if (response.data.result.code === 0) {


                    productsSelected.forEach(async (dato) => {
                        // Realizar la solicitud POST con Axios
                        if (dato.cantidad !== 'Total') {
                            const valuex = {
                                pedido: lastId,
                                producto: dato.id,
                                costo: dato.costo,
                                venta: dato.ventafinal,
                                cantidad: dato.cantidad,
                                descuento: dato.descuentofinal,
                                inventario: dato.inventario,
                                stock: dato.orden

                            }

                            const responsex = await pedprodPost(valuex);

                            if (responsex.data.code === 0) {
                                if (responsex.data.result.code === 0) {
                                } else {
                                    message.error(responsex.data.message);
                                }
                            } else {
                                message.error(responsex.data.message);
                            }
                        }

                    });


                    //message.success(CREATE_OK);
                    setIsOk(true);
                    setOpen(true);
                    setTimeout(() => {
                        closeAllVentanas(); fetchPedidoEnCurso(); fetchProductsActivos();
                    }, 2000);

                } else {
                    setIsOk(false);
                    message.error(CREATE_NOK);
                }
            } else {
                setIsOk(false);
                message.error(CREATE_NOK + response.data.message);
            }


        } catch (error) {
            setIsOk(false);
            message.error(error.message);
        }
        setLoadTwoDrawer(false);

    };

    const onChangeSelect = (value) => {
        setIdClente(value);
        //const filtro = customerSelected.filter(item => item.id === value);
        //setClienteSeleccionado(filtro[0]);

    }
    const onChangeTimePicker = (date, dateString) => {
        setTimePickerData(dateString);
    };
    const onChangeDatePicker = (date, dateString) => {
        setDataPickerData(dateString);
    };
    const addClient = () => {
        let decodedToken = jwtDecode(localStorage.getItem('tk'));
        formCliente.resetFields();
        formCliente.setFieldsValue({ id: '', local: decodedToken.data.local });
        setOpenClient(true);
        setMaxIdCliente(true);
    };
    const editClient = () => {
        const filtro = customerSelected.filter(item => item.id === idCliente);
        formCliente.resetFields();
        formCliente.setFieldsValue(filtro[0]);
        setOpenClient(true);
        setMaxIdCliente(false);
    }
    const handleFinishFormCliente = async (values) => {


        setLoadingFormCliente(true);
        for (const key in values) {
            if (values[key] === undefined) {
                values[key] = '';
            }

        }

        try {
            let response = [];


            values['id'] === '' ? response = await customerPost(values) :

                response = await customerPut(values['id'], values);

            if (response.data.code === 0) {
                if (response.data.result.code === 0) {
                    message.success(values['id'] === '' ? CREATE_OK : UPDATE_OK);
                    fecthCustomer();
                    if (values['id'] === '') {
                        setIdClente(response.data.result.lastId);
                    } else {

                    }
                    closeModal();

                } else {
                    message.error(values['id'] === '' ? CREATE_NOK + response.data.message : UPDATE_NOK + response.data.message);
                }
            } else {
                message.error(values['id'] === '' ? CREATE_NOK + response.data.message : UPDATE_NOK + response.data.message);
            }

        } catch (error) {

            message.error(CREATE_NOK + error.message);
        }
        setLoadingFormCliente(false);

    }
    return (
        <div>
            <Row>
                <Col span={24}>
                    <Button type="dashed" block onClick={showDrawer} style={{ color: "#2336b0" }}><ShopOutlined /> Nuevo Pedido </Button>
                </Col>

            </Row>
            <Drawer
                placement="right"
                closable={true}
                onClose={onClose}
                open={visible}
                size="large"
                extra={
                    <Space>
                        <Search
                            placeholder="Buscar"
                            onChange={handleSearchChange}
                            style={{
                                width: 190,
                            }}
                            value={textoBuscar}
                        />
                        <ClearOutlined style={{ fontSize: 16, color: "#0e3789" }} onClick={cleanQuantity} />
                       
                        <ReloadOutlined style={{ fontSize: 16, color: "#0e3789" }} onClick={fetchProductsActivos} />
                        <Badge count={contador} onClick={handleNext}>
                            <Avatar shape="square" style={{
                                backgroundColor: '#fff',
                            }} icon={<ShoppingCartOutlined style={{ fontSize: 18, color: "#0000ff" }} />} />
                        </Badge>
                    </Space>
                }

            >

                <Spin spinning={loading}>
                    <List
                        style={{ marginTop: -15, marginLeft: -20 }}


                        grid={{
                            gutter: 16,
                            xs: 2,
                            sm: 2,
                            md: 3,
                            lg: 4,
                            xl: 4,
                            xxl: 4,
                        }}
                        size="small"
                        dataSource={productActivos}
                        renderItem={(item) => (
                            <List.Item>
                                <ProductCard item={item} cuenta={cuenta} resta={resta} quantities={quantities} setQuantities={setQuantities} />
                            </List.Item>
                        )}
                    />


                </Spin>

                <Drawer
                    title="Ultimo Paso"
                    placement="right"
                    //width={500}
                    closable={false}
                    onClose={closeHijo}
                    open={openHijo}
                    extra={
                        <Space>
                            <ArrowLeftOutlined style={{ fontSize: 16, color: "#ff0000" }} onClick={closeHijo} />
                            <Button size="small" icon={<CheckOutlined style={{ fontSize: 16, color: "#0000ff" }} />} onClick={handleReserve}> Reservar</Button>
                        </Space>
                    }
                >
                    <Spin spinning={loadTwoDrawer}>
                        <Table dataSource={productsSelected} columns={columns} bordered size="small"
                            className="custom-table" pagination={false}
                            rowClassName={(record) => record.cantidad === 'Total' ? 'custom-bold-row' : ''}

                        />
                        <br></br>
                        <Space direction="vertical">
                            <Space direction="horizontal">
                                <Typography.Text strong style={{ fontSize: 12 }}>Para quién...?</Typography.Text>
                                <Select
                                    size="small"
                                    showSearch
                                    style={{
                                        width: 200,

                                    }}
                                    placeholder="Seleccionar..."
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={customerSelected}
                                    onChange={onChangeSelect}
                                    value={clienteSeleccionado?.nombres}


                                />
                                <PlusOutlined onClick={addClient} style={{ fontSize: 12, color: "#0e3789" }} /><EditOutlined style={{ fontSize: 12, color: "#0e3789" }} onClick={editClient} />

                            </Space>
                            <Space direction="vertical" style={{ fontSize: 11, color: "#171775", marginLeft: 5 }}>

                                <span ><UserOutlined /> {clienteSeleccionado?.alias}</span>
                                <span ><HomeOutlined /> {clienteSeleccionado?.direccion1}</span>
                                <span ><WhatsAppOutlined /> {clienteSeleccionado?.celular1}</span>
                            </Space>
                            <Space direction="horizontal">
                                <Typography.Text strong style={{ fontSize: 12 }}>A que Hora ?</Typography.Text>
                                <TimePicker size="small" onChange={onChangeTimePicker} format="HH:mm" defaultValue={dayjs().add(2, 'Hour')} style={{ width: 100 }} />

                                <DatePicker size="small" onChange={onChangeDatePicker} style={{ width: 120 }} defaultValue={dayjs()} format={['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY']} />
                            </Space>
                        </Space>
                    </Spin>
                    <Modal
                        open={open}
                        onCancel={() => { setOpen(false) }}
                        footer={false}
                        wrapClassName="birthday-modal">
                        <Result
                            status={isOk ? "success" : "error"}
                            title={isOk ? "Reserva con exito...!" : "Un Error en la Reserva"}
                            className={isOk ? "custom-result" : ""}
                        />
                    </Modal>
                    <Modal
                        title="Datos del Cliente"
                        open={openClient}
                        onCancel={() => { setOpenClient(false) }}
                        footer={false}
                    >

                        <Spin spinning={loadingFormCliente}>
                            <Form

                                form={formCliente}
                                name="clienteForm"
                                labelCol={{
                                    flex: '100px',
                                }}
                                labelAlign="left"
                                labelWrap
                                wrapperCol={{
                                    flex: 1,
                                }}
                                colon={false}
                                style={{
                                    maxWidth: 600,
                                    fontSize: 8
                                }}
                                size="small"
                                onFinish={handleFinishFormCliente}
                                autoComplete="off"
                            >
                                <br></br>
                                <Form.Item
                                    label="Id"
                                    name="id"
                                    hidden
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Casa"
                                    name="direccion2"
                                    hidden
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Cel"
                                    name="celular2"
                                    hidden
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Local"
                                    name="local"
                                    hidden
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label={<span><UserOutlined /> Alias</span>}
                                    name="alias"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor ingresar Alias',
                                        },
                                    ]}
                                    style={{ marginBottom: '4px' }} // Ajusta el margen inferior
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label={<span><IdcardOutlined /> Nombres</span>}
                                    name="nombres"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor ingresar Nombres',
                                        },
                                    ]}
                                    style={{ marginBottom: '4px' }} // Ajusta el margen inferior
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={<span><HomeOutlined /> Casa</span>}
                                    name="direccion1"
                                    style={{ marginBottom: '4px' }} // Ajusta el margen inferior
                                >
                                    <Input.TextArea size="small" />
                                </Form.Item>

                                <Form.Item
                                    label={<span><WhatsAppOutlined /> Whatsapp</span>}
                                    name="celular1"
                                    style={{ marginBottom: '4px' }} // Ajusta el margen inferior
                                >
                                    <Input placeholder="+56 9 1234 5678" />
                                </Form.Item>
                                <Form.Item label=" ">
                                    <div style={{ paddingBottom: 5, float: 'right' }}>
                                        <Space align="end">
                                            <Button type="dashed" onClick={closeModal}>
                                                Cancel
                                            </Button>
                                            <Button type="primary" htmlType="submit">
                                                Enviar
                                            </Button>

                                        </Space>
                                    </div>

                                </Form.Item>
                            </Form>
                        </Spin>

                    </Modal>

                </Drawer>



            </Drawer>

        </div>
    );
}
export default PedidoNuevo;