import React, {  useState } from 'react';
import { Button, ConfigProvider, DatePicker,  Select, Space } from 'antd';
import es_ES from 'antd/lib/locale/es_ES'; // Importa la configuración regional para español
import dayjs from 'dayjs';
import 'dayjs/locale/es'; // Importa la configuración regional de dayjs para español
import { optionsFechas } from '../../data/constans';

import { SearchOutlined } from '@ant-design/icons';


dayjs.locale('es'); // Configura dayjs para usar el idioma español


const VentaFind = ({mostrar}) => {
      const [mode, setMode] = useState('month'); // Modo predeterminado es día
      const [dateBegin, setDateBegin] = useState(null);
      const [dateEnd, setDateEnd] = useState(null);
    
     
  const handleModeChange = (value) => {
    setMode(value);
    setDateBegin(null);
   //form1.setFieldValue({fecha:''});
  };




      const handleDatePickerChange = (date, dateString) => {
       
        if (date) {
          let start;
          let end;
          
    
          if (mode === 'week') {
            start = dayjs(date).startOf('week');
            end = dayjs(date).endOf('week');
          } else if (mode === 'month') {
            start = dayjs(date).startOf('month');
            end = dayjs(date).endOf('month');
          } else if (mode === 'year') {
            start = dayjs(date).startOf('year');
            end = dayjs(date).endOf('year');
          }
          if(mode !== 'date'){
            setDateBegin(start?.format('YYYY-MM-DD'));
            setDateEnd(end?.format('YYYY-MM-DD'));

          }else{
            setDateBegin(dateString);
            setDateEnd(dateString);
          }
        }
      };    
      const consultar = () =>{

        mostrar(dateBegin,dateEnd);
        
      };

  return (
    <div>
 <ConfigProvider locale={es_ES}>
    <Space>
    <Select style={{ width: 70 }} defaultValue="Mes" options={optionsFechas} onChange={handleModeChange} size='small' placeholder='Modo'  />
    <DatePicker 
      picker={mode}
      locale={es_ES} 
      onChange={handleDatePickerChange}
     
      size='small'
      value={dateBegin ? dayjs(dateBegin) : null}
     //defaultValue={dayjs()}
     //format={['DD/MM/YYYY']}
     style={{width:110}}
     
      
    />
    <Button size='small'  ><SearchOutlined onClick={consultar} style={{color:"#0000ff"}}/></Button>
    </Space>
  </ConfigProvider>
    </div>
  );
};
export default VentaFind;