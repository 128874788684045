import {  Space } from "antd"
import VentaFind from "../ventas/VentaFind"
//import { useEffect } from "react";


function RegularizaCostoControl({ mostrar }) {

   /* useEffect(() => {
        console.log('CostoControl');
    }, []);*/
    return (
        <div>
            <Space direction="vertical" align="start">
                <span>Filtrar por Fecha</span>
                <VentaFind mostrar={mostrar} />
            </Space>
            
               
        </div>
    )
}

export default RegularizaCostoControl
