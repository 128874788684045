import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Grid, Input, theme, Typography, Alert } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import LogoMERCU from '../../assetes/svg/LogoMERCU.svg'
import { auth } from '../../data/api';




const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title, Link } = Typography;

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('undefined');
  const { token } = useToken();
  const screens = useBreakpoint();

  useEffect(()=>{
    //.log('Login Effect');
    localStorage.setItem('tk', '');
  },[]);


  const onFinish = async (values) => {
    localStorage.setItem('tk', '');
    setLoading(true);
    setError('undefined');
    const document = {
      name: values['usuario'],
      password: values['password']
    };

    try {
      const response = await auth(document);
      if (typeof response.data.message !== 'undefined') {
        setError(response.data.message);
        setLoading(false);
      }


      if (typeof response.data.token !== 'undefined') {
        setError('undefined');
        setLoading(false);
        localStorage.setItem('tk', response.data.token);
        window.location.href = '/landing';
      }

    } catch (error) {
      //console.error('Error al obtener los datos:', error);
      //console.log(error);
      setError(error.message);
      setLoading(false);
    }


  };




  const styles = {
    container: {
      margin: "0 auto",
      padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px"
    },
    centro: {
      display: "flex",
      justifyContent: "center",


    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%"
    },
    forgotPassword: {
      float: "right"
    },
    header: {
      marginBottom: token.marginXL
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: screens.sm ? "100vh" : "auto",
      padding: screens.md ? "0px 0px" : "0px"
    },
    text: {
      color: token.colorTextSecondary
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3
    }
    
  };

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        <div style={styles.centro}>
          <img src={LogoMERCU} alt="" />
        </div>
        <div style={styles.header}>
          <Title style={styles.title}>ACCESO</Title>
          <Text style={styles.text}>
            Bienvenido a MERCU - Sistema Para Registrar Tus Ventas. Ingrese las credenciales para iniciar sesión.
          </Text>
        </div>
        <Form
          name="normal_login"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          layout="vertical"
          requiredMark="optional"
        >
          <Form.Item
            name="usuario"
            rules={[
              {
                required: true,
                message: "Por favor ingresar Usuario!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder="Usuario"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Por favor ingresar Password!",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          {error !== 'undefined' ?
            <Form.Item name="errorDet">
              <Alert type="error" message={error} banner />
            </Form.Item> : ('')}
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Recuerdame</Checkbox>
            </Form.Item>
            <a style={styles.forgotPassword} href="#!">
              Olvidaste tu password?
            </a>
          </Form.Item>
          <Form.Item style={{ marginBottom: "0px" }}>
            <Button block="true" type="primary" htmlType="submit" loading={loading}>
              {!loading ? 'Ingresar' : 'Validando...'}
            </Button>
            <div style={styles.footer}>
              <Text style={styles.text}>No tienes una cuenta</Text>{" "}
              <Link href="#!">Regístrate ahora</Link>
            </div>
          </Form.Item>
        </Form>
      </div>
    </section>
  );
}
