/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Popconfirm, Space, Table, message } from "antd";
import GastoFind from "./GastoFind";
import { DownloadOutlined, ReloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { getGastosLista } from "../../data/api";
import { useSelector } from "react-redux";
import { formatNumber } from "../../util";
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';



const GastoList = () => {

    const [tableGasto, setTableGasto] = useState([]);
    const [loading, setLoading] = useState(false);
    const [descripcion, setDescripcion] = useState('');
    const [begin, setBegin] = useState(dayjs().startOf('month').format('YYYY-MM-DD'));
    const [end, setEnd] = useState(dayjs().endOf('month').format('YYYY-MM-DD'));
    const dataToken = useSelector(state => state.data.data);
    const valResponsive = useSelector(state => state.responsive);

    useEffect(() => {
       // console.log('useEffect GastoLista');
        fetchGastosLista();
        setDescripcion("Periodo : " + begin + " Hasta " + end);
    }, [begin]);
    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            mobile: false,
            width: 80,

        },
        {
            title: 'Fecha',
            dataIndex: 'fecha',
            key: 'fecha',
            mobile: true,
            width: 80

        },
        {
            title: 'Proveedor',
            dataIndex: 'nombreproveedor',
            key: 'nombreproveedor',
            mobile: false,
            ellipsis: true,
            width: 100,


        },
        {
            title: '#',
            dataIndex: 'cantidad',
            key: 'cantidad',
            mobile: true,
            width: 40,

        },
        {
            title: 'Concepto',
            dataIndex: 'concepto',
            key: 'concepto',
            mobile: true,
            ellipsis: true

        },
        {
            title: 'Costo',
            dataIndex: 'costo',
            key: 'costo',
            mobile: true,
            width: 80,
            render: (text, record) => (
                <span>{formatNumber(record.costo)}</span>
            )
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
            mobile: false,
            width: 100

        },
    ];

    const filteredMenuItems = columns.filter(item => {
        if (valResponsive) {
            if (item.mobile) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }

    });

    const mostrar = (a, b) => {

        setEnd(b);
        setBegin(a);
        setDescripcion("Periodo : " + a + " Hasta " + b);

    }
    const fetchGastosLista = async () => {
        setLoading(true);
        try {
            const response = await getGastosLista(dataToken.local, begin, end);
            if (response.data.code === 0 && response.data.counts > 0) {
                const totalCantidad = response.data.results.reduce((acc, curr) => acc + curr.cantidad, 0);
                const total = response.data.results.reduce((acc, curr) => acc + curr.costo, 0);
                const addTotal = [...response.data.results, { id: '', key: -1, fecha: 'Articulos', nombreproveedor: '', cantidad: totalCantidad, concepto: 'Total', costo: total, estado: '' }];
                setTableGasto(addTotal);
            } else {
                setTableGasto([]);
                //setTotalCantidad(0);
                //setTotalGasto(0);
            }

        } catch (error) {
            message.error(error.message);
        }
        setLoading(false);
    };

    const downLoadExcel = () => {


        const fileType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(tableGasto);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const dataExcel = new Blob([excelBuffer], { type: fileType });
        saveAs(dataExcel, 'DetalleGastos' + fileExtension);

    };

    return (<>

        <div style={{ paddingBottom: 5 }}>
            <Space style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <GastoFind mostrar={mostrar} />
                <Space>
                    <Popconfirm
                        title="Advertencia"
                        description={<span>¿Estas seguro que deseas descargar la Excel?</span>}
                        okText="Si, Descargar"
                        cancelText="Cancelar"
                        onConfirm={downLoadExcel}
                    >
                        <Button type="link" size="small" icon={<DownloadOutlined style={{ color: "#0e3789" }} />} />
                    </Popconfirm>

                    <Button type="link" size="small" onClick={fetchGastosLista} icon={<ReloadOutlined style={{ color: "#0e3789" }} />} />
                </Space>
            </Space>
        </div>
        <Table dataSource={tableGasto}
            columns={filteredMenuItems}
            size="small"
            className="custom-table"
            loading={loading}
            bordered
            pagination={false}
            rowClassName={(record) => (record.concepto === 'Total' || record.fecha === 'Articulos') ? 'custom-bold-row' : ''}
        />
        <br></br>
        <Space align="center">

            <span style={{ fontSize: 12, color: "#5b5a63" }}><strong>{descripcion}</strong></span>
        </Space>
    </>);
};
export default GastoList;

