import React, { useEffect, useState } from 'react';
import { TrademarkCircleTwoTone, LogoutOutlined,  UserOutlined, LockOutlined } from '@ant-design/icons';
import { Layout, Space, theme, Dropdown, Button, Modal } from 'antd';
import LogoMERCUDark from '../../assetes/svg/LogoMERCUDark.svg';
import MyMenu from '../layout/MyMenu';

import System from '../system/System';
import { jwtDecode } from "jwt-decode";
import { useDispatch } from 'react-redux';
import { setDataToken } from '../../reducers/actions';
import Pedido from '../pedido/Pedido';
import ChangePassword from '../system/changePassword';
import Customer from '../customer/Customer';
import Product from '../product/Product';
import Categoria from '../categoria/Categoria';
import Ventas from '../ventas/Ventas';
import Gasto from '../gasto';
import Proveedor from '../proveedor';
import Regulariza from '../regulariza/Regulariza';

const { Header, Content, Footer, Sider } = Layout;



const Landing = () => {
    //.log('Landing');


    const [collapsedM, setCollapsedM] = useState(false);
    const [currentPage, setCurrentPage] = useState('pedido');
    //const [view, setView] = useState(false);
    const [viewFooter, setViewFooter] = useState(true);
    const [typeMenu, setTypeMenu] = useState('');
    const [userRol, setUserRol] = useState('normal');
    const [isPasswordChange, setIsPasswordChange] = useState(false);
    const [userId, setUserId] = useState('');
    const [userNombres, setUserNombres] = useState('');
    const [logo, setLogo] = useState('notimage.svg');
    //const [expire, setExpire] = useState('');
    const dispatch = useDispatch();

    const token = localStorage.getItem('tk');
    if (token === '') {
        window.location.href = "/";
    }

    useEffect(() => {
        //console.log('useEffect Landing');
        if (localStorage.getItem('tk') === '') {
            window.location.href = "/";
        }
        let decodedToken = [];
        let responsive = typeMenu === 'responsive' ? true : false;
        try {
            decodedToken = jwtDecode(localStorage.getItem('tk'));
            setUserId(decodedToken.data.id);
            setUserNombres(decodedToken.data.nombres);
            setLogo(decodedToken.data.logo);
            const expire = new Date(decodedToken.exp * 1000);
            //setExpire(expiracion);
            const ahora = new Date();
            if (ahora >= expire) {
                //console.log('El token ha expirado');
                window.location.href = "/";
              } else{
                //console.log('el token no ha expirado');
              }

        } catch (error) {
            
            window.location.href = "/";
        }
        //console.log('decode', decodedToken);
        dispatch(setDataToken(decodedToken, responsive));
        setUserRol(decodedToken.data?decodedToken.data.rol:'');

    }, [dispatch,typeMenu]);
    
    const items = [
        {
            key: '1',
            label: (
                <a rel="noopener noreferrer" href="#!">
                    Hola, {userNombres}
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a href='#!' onClick={handleChangePassword}>
                    <LockOutlined /> Cambiar Contraseña
                </a>
            ),
        },
        {
            key: '3',
            label: (
                <a rel="noopener noreferrer" href="#!" onClick={handleClose}>
                    <LogoutOutlined style={{ fontSize: '16px' }} /> Salir
                </a>
            ),
        },
    ];

    function handleChangePassword() {
        setIsPasswordChange(true);
    }


    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();


    function handleClose() {
        localStorage.setItem('tk', '');
        window.location.href = '/';
    }

    const handleMenuClick = (page) => {
        setCurrentPage(page);
        //console.log(typeMenu);
        if (typeMenu === 'responsive') {
            collaseMenu(true, 'clickTrigger');
            setCollapsedM(true);
        } else { setViewFooter(true); }


    };


    const collaseMenu = (collapsed, type) => {

        if (type === 'responsive') {
            setTypeMenu(type);
        }

        //setView(collapsed);
        if (typeMenu === 'responsive') {
            setViewFooter(collapsed);
        } else {
            setViewFooter(true);
        }

        setCollapsedM(collapsed);
    };
    return (
        <>
            <Layout style={{  minHeight: '100vh',  }}>
                <Sider
                    breakpoint="lg"
                    collapsedWidth="0"
                    collapsible
                    collapsed={collapsedM}
                    onBreakpoint={(broken) => {
                        //setView(broken);

                    }}
                    onCollapse={collaseMenu}
                    style={{ marginTop: -50 }}
                >
                    <div className="demo-logo-vertical">
                        <br /><br /><br /><br />
                        <img src={LogoMERCUDark} alt='' />
                    </div>
                    <br />
                    <MyMenu userRole={userRol} onMenuClick={handleMenuClick} />
                </Sider>
                <Layout>
                    <Header
                    
                        style={{
                            position: 'sticky',
                            padding: 0,
                            background: colorBgContainer,
                            paddingTop: 10,
                            paddingRight: 10
                        }}
                    >
                        <div className="space-align-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className="space-align-block" style={{ flex: 1 }}>
                                <Space>
                                    <div style={{ minWidth: 40 }}></div>
                                </Space>
                                <Space align='start' style={{ paddingTop: 5 }}>
                                     <img src={`https://irest.cl/img-mercu/${logo}`} alt='' />
                                </Space>
                            </div>
                            <Space align='end' style={{ paddingBottom: 25 }}>
                               { /*<SearchOutlined style={{ fontSize: '16px' }} /> */}
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                    placement="bottom"
                                    arrow={{
                                        pointAtCenter: true,
                                    }}

                                >
                                    <Button><UserOutlined /></Button>
                                </Dropdown>
                            </Space>
                        </div>
                    </Header>

                    {viewFooter ? <Content
                        style={{
                            margin: '10px 10px 0',
                        }}
                    >
                        <div
                            style={{
                                padding: 10,
                                minHeight: 360,
                                background: colorBgContainer,
                                borderRadius: borderRadiusLG,
                            }}
                        >
                            {currentPage === 'products' && <Product />}
                            {currentPage === 'customer' && <Customer />}
                            {currentPage === 'system' && <System />}
                            {currentPage === 'pedido' && <Pedido />}
                            {currentPage === 'categoria' && <Categoria />}
                            {currentPage === 'ventas' && <Ventas />}
                            {currentPage === 'gastos' && <Gasto />}
                            {currentPage === 'proveedor' && <Proveedor />}
                            {currentPage === 'regulariza' && <Regulariza />}
                        </div>
                    </Content> : ('')}
                    {viewFooter ? <Footer
                        style={{
                            textAlign: 'center',
                            fontSize:11
                        }}
                    >
                        <TrademarkCircleTwoTone /> MERCU - Registrar Tus Ventas ©{new Date().getFullYear()} <br/>Creado por Vladimir System
                    </Footer> : ('')}
                </Layout>
            </Layout>
            <Modal title="Cambio de Password" open={isPasswordChange} onCancel={() => { setIsPasswordChange(false) }} footer={false}>
                <ChangePassword idUsuario={userId} nombres={userNombres} setIsPasswordChange={setIsPasswordChange} />
            </Modal>
        </>
    );
};
export default Landing;
