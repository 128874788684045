
import { Menu, } from 'antd';
import { ContactsOutlined, ControlOutlined, DollarOutlined, FallOutlined, FieldTimeOutlined, FolderOutlined, LogoutOutlined, ProductOutlined, ToolOutlined, TruckOutlined } from '@ant-design/icons';


const MyMenu = ({ userRole, onMenuClick }) => {
  const menuItems = [
    {
      key: '1',
      icon: <FieldTimeOutlined style={{ color: "white" }} />,
      label: 'Pedidos',
      rol: 'normal'
    },
    {
      key: '2',
      icon: <DollarOutlined style={{ color: "#34eb7d" }} />,
      label: 'Ventas',
      rol: 'admin'
    },
    {
      key: '3',
      icon: <ProductOutlined style={{ color: "#6f9cfc" }} />,
      label: 'Productos',
      rol: 'admin'
    },
    {
      key: '4',
      icon: <FallOutlined style={{ color: "#ff0000" }} />,
      label: 'Gastos D/I',
      rol: 'admin'
    },
    {
      key: '5',
      icon: <TruckOutlined style={{ color: "#faf5af" }} />,
      label: 'Proveedores',
      rol: 'admin'
    },
    {
      key: '6',
      icon: <ContactsOutlined style={{ color: "#acfaf3" }} />,
      label: 'Clientes',
      rol: 'normal'
    },
    {
      key: '7',
      icon: <FolderOutlined style={{ color: "#edf7f7" }} />,
      label: 'Categorias',
      rol: 'admin'
    },
    {
      key: '8',
      icon: <ControlOutlined style={{ color: "#f5bd07" }} />,
      label: 'Regularizar',
      rol: 'admin'
    },
    {
      key: '9',
      icon: <ToolOutlined style={{ color: "#edf7f7" }} />,
      label: 'Sistema',
      rol: 'admin'
    },
    {
      key: '10',
      icon: <LogoutOutlined style={{ color: "#f50753" }} />,
      label: 'Salir',
      rol: 'normal'
    }

  ];
  const handleClick = (page) => {
    if(page==='salir'){
      window.location.href = "/";
      return;
    }
    onMenuClick(page);
  };

  const handleMenuClick = (key) => {
    switch (key) {
      case '1':
        handleClick('pedido');
        break;
      case '2':
        handleClick('ventas');
        break;
      case '3':
        handleClick('products');
        break;
      case '4':
        handleClick('gastos')
        break;
      case '5':
        handleClick('proveedor')
        break;
      case '6':
        handleClick('customer')
        break;
      case '7':
        handleClick('categoria')
        break;
      case '8':
        handleClick('regulariza')
        break;
      case '9':
        handleClick('system')
        break;
      case '10':
        handleClick('salir')
        break;
      default:
        break;
    }
  };

  const filteredMenuItems = menuItems.filter(item => {
    if (userRole === 'superadmin') {
      return true;
    }
    if (userRole === 'admin' && (item.rol === 'admin' || item.rol === 'normal')) {
      return true;
    }
    if (userRole === item.rol) {
      return true;
    }
    return false;
  });
  return (
    <Menu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={['1']}
      onClick={({ key }) => handleMenuClick(key)}
      items={filteredMenuItems}

    />
  );
}
export default MyMenu;