/* eslint-disable react-hooks/exhaustive-deps */
import { DeleteOutlined, LockOutlined, PlusOutlined, QuestionOutlined } from "@ant-design/icons";
import { Table, Button, message, Space, Modal, Form, Input, Select, Spin, Typography, Tooltip, Popconfirm } from "antd";
import { getUsersLocal, userDel, userPost, userPut } from "../../data/api";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DELETE_OK, DELETE_NOK, CREATE_OK, CREATE_NOK, UPDATE_OK, UPDATE_NOK } from "../../constans";
import ChangePassword from "./changePassword";

const { Option } = Select;

const Users = () => {

    const [usersLocal, setUsersLocal] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);

    const [isUser, setIsUser] = useState(false);
    const [isPasswordChange, setIsPasswordChange] = useState(false);

    const [titleModal, setTitleModal] = useState('');
    const [idFormUser, setIdFormUser] = useState('');
    const [nombresFormUser, setNombresFormUser] = useState('');
    const [form] = Form.useForm();
    const dataToken = useSelector(state => state.data.data);
    const valResponsive = useSelector(state => state.responsive);
    useEffect(() => {


        fetchUsersLocal();
    }, []);

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            mobile: false,
            sorter: (a, b) => a.id - b.id,
            defaultSortOrder: 'descend',
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            mobile: true,
        },
        {
            title: 'Alias',
            dataIndex: 'alias',
            key: 'alias',
            mobile: true,
        },
        {
            title: 'Rol',
            dataIndex: 'rol',
            key: 'rol',
            mobile: true,
            render: (text, record) => (
                <span>{record.rol === 'admin' ? 'Admin' : 'Normal'}</span>
            )
        },
        {
            title: 'Acceso',
            dataIndex: 'activo',
            key: 'activo',
            mobile: true,
            render: (text, record) => (
                <span>{record.activo === '1' ? <Typography.Text type="success">Activo</Typography.Text> : <Typography.Text type="danger">Inactivo</Typography.Text>}</span>
            )
        },
        {
            title: 'Nombres',
            dataIndex: 'nombres',
            key: 'nombres',
            mobile: false,

        },


        {
            title: 'Expira',
            dataIndex: 'expira',
            key: 'expira',
            mobile: false
        },


    ];

    const filteredMenuItems = columns.filter(item => {
        if (valResponsive) {
            if (item.mobile) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }

    });

    const fetchUsersLocal = async () => {
        setLoading(true);
        try {
            const response = await getUsersLocal(dataToken.local);
            if (response.data.code === 0 && response.data.counts > 0) {
                const formattedData = response.data.results.map((item, index) => ({
                    ...item,
                    key: index,
                }));
                setUsersLocal(formattedData);
            } else {
                setUsersLocal([]);
            }

        } catch (error) {
            message.error(error.message);
        }
        setLoading(false);
    }
    const addUser = () => {
        form.resetFields();
        setTitleModal('Agregar Usuario');
        setIsUser(true);
        setIdFormUser('');
        setNombresFormUser('');
        form.setFieldsValue({ local: dataToken.local });


    }

    const handleEditUser = (record) => {
        setTitleModal('Actualizar Usuario');
        form.resetFields();
        form.setFieldsValue(record);
        setIsUser(true);
        setIdFormUser(record.id);
        setNombresFormUser(record.nombres);

    };
    const handleDeleteUser = async () => {
        try {
            const response = await userDel(idFormUser);
            if (response.data.code === 0) {
                if (response.data.result.code === 0) {
                    message.success(DELETE_OK);
                    fetchUsersLocal();
                    setIsUser(false);
                } else {
                    message.error(DELETE_NOK + response.data.result.message);
                }

            } else {
                message.error(DELETE_NOK + response.data.message);
            }
        } catch (error) {
            message.error(DELETE_NOK + error.message);
        }

    };
    const handleCloseUser = () => {
        setIsUser(false);
    };
    const handlePass = (id, nombres) => {
        setIdFormUser(id);
        setNombresFormUser(nombres);
        setIsPasswordChange(true);
    };
    const onFinish = async (values) => {
        setLoadingModal(true);
        for (const key in values) {
            if (values[key] === undefined) {
                values[key] = '';
            }
        }

        // console.log(values);

        try {
            let response = [];

            values['id'] === '' ? response = await userPost(values) :

                response = await userPut(values['id'], values);

            if (response.data.code === 0) {
                if (response.data.result.code === 0) {
                    message.success(values['id'] === '' ? CREATE_OK : UPDATE_OK);
                    setIsUser(false);
                    fetchUsersLocal();
                } else {
                    message.error(values['id'] === '' ? CREATE_NOK + response.data.message : UPDATE_NOK + response.data.message);
                }
            } else {
                message.error(values['id'] === '' ? CREATE_NOK + response.data.message : UPDATE_NOK + response.data.message);
            }

        } catch (error) {
   
            message.error(CREATE_NOK + error.message);
        }
        setLoadingModal(false);
    };

    const [lastClickTime, setLastClickTime] = useState(0);

    const handleClick = (record) => {

        const clickTime = new Date().getTime();
        const doubleClickDelay = 300;

        if (clickTime - lastClickTime < doubleClickDelay) {
            handleEditUser(record);
        }

        setLastClickTime(clickTime);
    };

    return (<>
        <div style={{ paddingBottom: 5, float: 'right' }}>
            <Space align="end" size="small">
                <Button type="link" size="small" onClick={addUser} icon={<PlusOutlined style={{ color: "#0e3789" }} />} />
                <Tooltip title="Doble click en la fila para detalle" color="#0e3789" key="#0e3789" >
                    <Button type="link" size="small" icon={<QuestionOutlined style={{ color: "#0e3789" }} />} />
                </Tooltip>
            </Space>
        </div>
        <Table
            dataSource={usersLocal}
            columns={filteredMenuItems}
            size="small"
            className="custom-table responsive-table"
            pagination={false}
            loading={loading}
            onRow={(record) => ({
                onClick: () => handleClick(record),
            })}
        />
        <Modal
            title={titleModal}
            open={isUser}
            onCancel={handleCloseUser}
            maskClosable={false}
            footer={false}
        >
            <Spin spinning={loadingModal}>
                <Form
                    autoComplete="off"
                    form={form}
                    name="userForm"
                    labelCol={{
                        flex: '80px',
                    }}
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{
                        flex: 1,
                    }}
                    colon={false}
                    style={{
                        maxWidth: 600,
                        fontSize: 8
                    }}
                    size="small"
                    onFinish={onFinish}
                >
                    <br></br>
                    <Form.Item
                        label="Id"
                        name="id"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Local"
                        name="local"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Usuario"
                        name="username"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        style={{ marginBottom: '6px' }} // Ajusta el margen inferior
                    >
                        <Input />
                    </Form.Item>

                    {idFormUser === '' ? <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingresar',
                            },
                        ]}
                        style={{ marginBottom: '6px' }} // Ajusta el margen inferior
                    >
                        <Input.Password placeholder="Numero y Letras" />
                    </Form.Item> : null}

                    <Form.Item
                        name="rol"
                        label="Rol"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingresar',
                            },
                        ]}
                        style={{ marginBottom: '6px' }}
                    >
                        <Select placeholder="Selecciona Rol">
                            <Option value="normal">Normal</Option>
                            <Option value="admin">Admin</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Alias"
                        name="alias"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        style={{ marginBottom: '6px' }} // Ajusta el margen inferior
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Nombres"
                        name="nombres"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingresar',
                            },
                        ]}
                        style={{ marginBottom: '6px' }} // Ajusta el margen inferior
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="activo"
                        label="Acceso"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingresar',
                            },
                        ]}
                        style={{ marginBottom: '15px' }}
                    >
                        <Select placeholder="Selecciona Acceso">
                            <Option value="1">Activo</Option>
                            <Option value="0">Inactivo</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label=" ">
                        <div style={{ paddingBottom: 5, float: 'right' }}>
                            <Space align="end">
                                {idFormUser ? (<Popconfirm
                                    title="Eliminar Usuario"
                                    description="¿Estas seguro que deseas eliminar...?"
                                    onConfirm={handleDeleteUser}
                                    okText="Si Eliminar"
                                    cancelText="Cancelar"
                                ><Button danger><DeleteOutlined style={{ color: "#ff0000" }} /> </Button></Popconfirm>) : null}
                                {idFormUser ? (
                                    <Button type="dashed" onClick={() => handlePass(idFormUser, nombresFormUser)}>
                                        <LockOutlined style={{ color: "#FAAB2F" }} />
                                    </Button>) : null}
                                <Button type="dashed" onClick={() => setIsUser(false)}>
                                    Cancel
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    Enviar
                                </Button>
                            </Space>
                        </div>

                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
        <Modal title="Cambio de Password" open={isPasswordChange} onCancel={() => { setIsPasswordChange(false) }} footer={false}>
            <ChangePassword idUsuario={idFormUser} nombres={nombresFormUser} setIsPasswordChange={setIsPasswordChange} />
        </Modal>
    </>);
};
export default Users;