import { Button, Popconfirm, Space, Spin, Table, message } from "antd";
import { formatNumber } from "../../util";
import { useSelector } from "react-redux";
import { PlayCircleOutlined } from "@ant-design/icons";
import { updatecostpedidoPut, updatecostpedprodPut } from "../../data/api";
import { UPDATE_NOK, UPDATE_OK } from "../../constans";
import { useState } from "react";


function RegularizaCostoTable({regularizaData, descripcion, loading, fecthProducts}) {
    const[sping,setSping] = useState(false);
    const valResponsive = useSelector(state => state.responsive);
    const columns = [
        {
            title: 'Nro',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id,
            defaultSortOrder: 'descend',
            mobile: false,
            width: 60,
            render: (text, record) => (
                record.costopedprod !== record.costoproducto? <span style={{color:"#ff0000"}}>{(record.id)}</span>:<span>{(record.id)}</span>
               ),
        },
        {
            title: 'Fecha',
            dataIndex: 'fecha',
            key: 'fecha',
            mobile: false,
            width: 65,
            render: (text, record) => (
                record.costopedprod !== record.costoproducto? <span style={{color:"#ff0000"}}>{(record.fecha)}</span>:<span>{(record.fecha)}</span>
               ),
           
        },
        {
            title: 'Nombre',
            dataIndex: 'nombreproducto',
            key: 'nombreproducto',
            mobile: true,
            render: (text, record) => (
                record.costopedprod !== record.costoproducto? <span style={{color:"#ff0000"}}>{(record.nombreproducto)}</span>:<span>{(record.nombreproducto)}</span>
               ),
           
        },
        {
            title: 'Cnt',
            dataIndex: 'cantidadpedpro',
            key: 'cantidadpedpro',
            mobile: true,
            width: 50,
            render: (text, record) => (
                record.costopedprod !== record.costoproducto? <span style={{color:"#ff0000"}}>{(record.cantidadpedpro)}</span>:<span>{(record.cantidadpedpro)}</span>
               ),
           
              
        },
        {
            title: 'CostVen',
            dataIndex: 'costoventa',
            key: 'costoventa',
            mobile: false,
            render: (text, record) => (
               record.costopedprod !== record.costoproducto? <span style={{color:"#ff0000"}}>{formatNumber(record.costoventa)}</span>:<span>{formatNumber(record.costoventa)}</span>
              ),
              width: 60
            
        },
        {
            title: 'CostoPedido',
            dataIndex: 'costopedprod',
            key: 'costopedprod',
            mobile: true,
            render: (text, record) => (
               record.costopedprod !== record.costoproducto? <span style={{color:"#ff0000"}}>{formatNumber(record.costopedprod)}</span>:<span>{formatNumber(record.costopedprod)}</span>
              ),
              width: 60
            
        },
        {
            title: 'CostoProducto',
            dataIndex: 'costoproducto',
            key: 'costoproducto',
            mobile: true,
            render: (text, record) => (
                record.costopedprod !== record.costoproducto? <span style={{color:"#ff0000"}}>{formatNumber(record.costoproducto)}</span>:<span>{formatNumber(record.costoproducto)}</span>
               ),
              width: 60
            
        }
        
        
    ];

    const filteredMenuItems = columns.filter(item => {
        if (valResponsive) {
            if (item.mobile) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    
    });

    const executeRegularization = async () => {

        const filtrado = regularizaData.filter(item => item.costopedprod !== item.costoproducto);
       //console.log(filtrado);
        if(filtrado.length === 0){
            message.info('No se regulariza. Data OK');
            return;
        }
//actualizar
setSping(true);
try {
    

    filtrado.forEach(async (dato) => {
       const values = {costo:dato.costoproducto}
       await updatecostpedprodPut(dato.key,values);
       //console.log('updatePedProd',dato.key);
    });

    setTimeout(() => {
        updateProduct(filtrado);
        //console.log('actualizo');
    },2000);
    

    setTimeout(() => {
        setSping(false);fecthProducts(); message.success(UPDATE_OK);
    }, 3000);
   
   
    


} catch (error) {
    //console.log(error);
    setSping(false);
    message.error(UPDATE_NOK + error.message);
}



    };

    const updateProduct= async (data)=>{

        data.forEach(async (dato) => {
            const values = {costo:dato.costoproducto}
            console.log('updateProduct:',dato.id);
            await updatecostpedidoPut(dato.id,values);
        });

    };

    return (
        <>
        <Spin spinning={sping}>
            <div style={{ paddingBottom: 3 }}>
                <Space style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <Space>
                        <span style={{ fontSize: 12 }}>{descripcion}</span>
                    </Space>
                    <Popconfirm
                        title="Advertencia"
                        description={<span>¿Estas seguro que deseas Ejecutar la Regularización...?</span>}
                        okText="Si, Ejecutar"
                        cancelText="Cancelar"
                        onConfirm={executeRegularization}
                    >
                        <Button type="link" size="small" icon={<PlayCircleOutlined style={{ color: "#0e3789" }} />} > Ejecutar</Button>
                    </Popconfirm>
            
                </Space>
            </div>

            <Table dataSource={regularizaData}
                columns={filteredMenuItems}
                size="small"
                className="custom-table"
                loading={loading}
                pagination={false}
            />
            </Spin>

        </>
    );
}

export default RegularizaCostoTable
