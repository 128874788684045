
import { Button, ConfigProvider, DatePicker, Form, Input,  Modal, Popconfirm, Select, Space, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { gastoDel, gastoPost, gastoPut } from "../../data/api";
import { CREATE_NOK, CREATE_OK, DELETE_NOK, DELETE_OK, UPDATE_NOK, UPDATE_OK } from "../../constans";
import { DeleteOutlined } from "@ant-design/icons";
import es_ES from 'antd/lib/locale/es_ES';
import dayjs from 'dayjs';
import GastoDetails from "./GastoDetails";

const { Option } = Select;
const GastoForm = ({ openModal, closeModal, titleModal, datosForm, fetchData, proveedores }) => {
    const [loadingForm, setLoadingForm] = useState(false);
    const [idGasto, setIdGasto] = useState('');
    const [beginDate, setBeginDate] = useState(null);
    const [viewForm, setViewForm] = useState(true);
    const [formGasto] = Form.useForm();


    useEffect(() => {
        
        if (datosForm.id) {
            formGasto.setFieldsValue(datosForm);
            setIdGasto(datosForm.id);
            setBeginDate(datosForm.fecha);
            setViewForm(true);
            //fetchData();
        } else {
            setBeginDate(dayjs().format('YYYY-MM-DD'));
            formGasto.resetFields();
            formGasto.setFieldsValue({ local: datosForm.local, estado: 'PAGADO', referencia: 'N/A', numero: 'S/N',tipo:'DIRECTO' });
            
            setIdGasto('');
        };

    }, [formGasto, datosForm]);

    const closeModalMain = () =>{
        closeModal();
        setViewForm(true);
    }
    const handleChangeDate = (date, dateString) => {
        
        setBeginDate(dateString);
    }


    const handleFinish = async (values) => {
        setLoadingForm(true);
        for (const key in values) {
            if (values[key] === undefined) {
                values[key] = '';
            }


        }
        if (values['costo'] === '') {
            values['costo'] = 0;
        }
        values['fecha'] = beginDate;
       // console.log(values);
        try {
            let response = [];


            values['id'] === '' ? response = await gastoPost(values) :

                response = await gastoPut(values['id'], values);

            if (response.data.code === 0) {
                if (response.data.result.code === 0) {
                    message.success(values['id'] === '' ? CREATE_OK : UPDATE_OK);
                    if(values['id'] !== '')
                        {
                            setViewForm(true);
                        closeModal();
                    }
                    setIdGasto(response.data.result.lastId);
                    fetchData();
                    setViewForm(false);
                } else {
                    message.error(values['id'] === '' ? CREATE_NOK + response.data.message : UPDATE_NOK + response.data.message);
                }
            } else {
                message.error(values['id'] === '' ? CREATE_NOK + response.data.message : UPDATE_NOK + response.data.message);
            }

        } catch (error) {
            //console.log(error);
            message.error(CREATE_NOK + error.message);
        }
        setLoadingForm(false);
    };

    const deleteGasto = async () => {
        try {
            const response = await gastoDel(idGasto);
            if (response.data.code === 0) {
                if (response.data.result.code === 0) {

                    if (response.data.result.code === 0) {
                        message.success(DELETE_OK);
                        closeModalMain();
                        fetchData();
                    } else {
                        message.error(DELETE_NOK + response.data.result.message);
                    }


                } else {
                    message.error(DELETE_NOK + response.data.result.message);
                }

            } else {
                message.error(DELETE_NOK + response.data.message);
            }
        } catch (error) {
            message.error(DELETE_NOK + error.message);
        }

    };
    return (<>
        <Modal
            title={titleModal}
            open={openModal}
            onCancel={closeModalMain}
            maskClosable={false}
            footer={false}
        >
            <Spin spinning={loadingForm}>
                {viewForm?<Form

                    form={formGasto}
                    name="GastoForm"
                    labelCol={{
                        flex: '100px',
                    }}
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{
                        flex: 1,
                    }}
                    colon={false}
                    style={{
                        maxWidth: 600,
                        fontSize: 8
                    }}
                    size="small"
                    onFinish={handleFinish}
                    autoComplete="off"
                >
                    <br></br>
                    <Form.Item
                        label="Id"
                        name="id"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Local"
                        name="local"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Tipo"
                        name="tipo"

                        style={{ marginBottom: '4px' }} // Ajusta el margen inferior
                    >
                        <Select>
                            <Option key={1} value="DIRECTO">DIRECTO</Option>
                            <Option key={2} value="INDIRECTO">INDIRECTO</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="proveedor"
                        label="Proveedor"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingresar',
                            },
                        ]}
                        style={{ marginBottom: '4px' }}
                    >

                        <Select placeholder="Seleccione" showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={proveedores}
                        >
                        </Select>

                    </Form.Item>

                    <Form.Item
                        label="Fecha"
                        name="fecha"
                        style={{ marginBottom: '4px' }} // Ajusta el margen inferior
                        autoComplete="off"
                    >
                        <ConfigProvider locale={es_ES}>
                            <DatePicker
                                locale={es_ES}
                                onChange={handleChangeDate}
                                size='small'
                                value={beginDate ? dayjs(beginDate) : null}
                            />
                        </ConfigProvider>



                    </Form.Item>

                    <Form.Item
                        label="Costo T."
                        name="costo"
                        style={{ marginBottom: '4px' }} // Ajusta el margen inferior
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label="Referen"
                        name="referencia"

                        style={{ marginBottom: '4px' }} // Ajusta el margen inferior
                    >
                        <Select>
                            <Option key={1} value="N/A">N/A</Option>
                            <Option key={2} value="BOLETA">BOLETA</Option>
                            <Option key={3} value="FACTURA">FACTURA</Option>
                            <Option key={4} value="OTRO">OTRO</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Nro/Ref"
                        name="numero"
                        style={{ marginBottom: '4px' }} // Ajusta el margen inferior
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Estado"
                        name="estado"
                        style={{ marginBottom: '4px' }} // Ajusta el margen inferior
                    >
                        <Select>
                            <Option key={1} value="PAGADO">PAGADO</Option>
                            <Option key={2} value="DEBE">DEBE</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Extra"
                        name="extra"
                        style={{ marginBottom: '4px' }} // Ajusta el margen inferior
                    >
                        <Input.TextArea />
                    </Form.Item>

                    <Form.Item label=" ">
                        <div style={{ paddingBottom: 5, float: 'right' }}>
                            <Space align="end">
                                {datosForm.id ? <Popconfirm
                                    title="Advertencia"
                                    description={<span>¿Deseas eliminar el gasto {datosForm.nombreproveedor}?</span>}
                                    okText="Si, Eliminar"
                                    cancelText="Cancelar"
                                    onConfirm={deleteGasto}
                                >
                                    <Button danger><DeleteOutlined /></Button>
                                </Popconfirm> : null}
                                <Button type="dashed" onClick={closeModalMain}>
                                    Cancel
                                </Button>
                                <Button type="primary" htmlType="submit" >
                                {datosForm.id?<span>Actualizar</span>:<span>Siguiente</span> }
                                </Button>
                                
                                

                            </Space>
                        </div>

                    </Form.Item>
                </Form>:null}
                {idGasto?<GastoDetails idGasto={idGasto} fetchData={fetchData}/>:null}
            </Spin>
        </Modal>

    </>);
};
export default GastoForm;