/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Form, Image, Input, InputNumber, Modal, Popconfirm, Select, Space, Spin, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import { productDel, productPost, productPut } from "../../data/api";
import { CREATE_NOK, CREATE_OK, DELETE_NOK, DELETE_OK, UPDATE_NOK, UPDATE_OK } from "../../constans";
import { DeleteOutlined, EyeOutlined, ProductOutlined, UploadOutlined } from "@ant-design/icons";
const { Option } = Select;
const ProductForm = ({ openModal, closeModal, titleModal, datosForm, fetchData, categories }) => {
    const [loadingForm, setLoadingForm] = useState(false);
    const [idProduct, setIdProduct] = useState('');
    const [imagenp, setImagenp] = useState('');
    const [formProduct] = Form.useForm();

    useEffect(() => {

        formProduct.resetFields();
        if (datosForm.id) {
            formProduct.setFieldsValue(datosForm);
            setIdProduct(datosForm.id);
            setImagenp(datosForm.foto);
        } else {

            formProduct.setFieldsValue({ local: datosForm.local });
            setIdProduct('');
        }
    }, [formProduct, datosForm]);

    const closeModal1 = () => {
        closeModal(false);
        //formProduct.resetFields();
    }


    const handleFinish = async (values) => {
        setLoadingForm(true);
        for (const key in values) {
            if (values[key] === undefined) {
                values[key] = '';
            }
            if (key === 'costo' && values[key] === '') {
                values[key] = '0';
            }
            if (key === 'orden' && values[key] === '') {
                values[key] = '1';
            }
            if (key === 'venta' && values[key] === '') {
                values[key] = '0';
            }
            if (key === 'descuento' && values[key] === '') {
                values[key] = '0';
            }
            if (key === 'inventario' && values[key] === '') {
                values[key] = 'NO';
            }
        }
        values['foto'] = imagenp;
       


        try {
            let response = [];


            values['id'] === '' ? response = await productPost(values) :

                response = await productPut(values['id'], values);



            if (response.data.code === 0) {
                if (response.data.result.code === 0) {
                    message.success(values['id'] === '' ? CREATE_OK : UPDATE_OK);
                    closeModal1();
                    fetchData();
                } else {
                    message.error(values['id'] === '' ? CREATE_NOK + response.data.message : UPDATE_NOK + response.data.message);
                }
            } else {
                message.error(values['id'] === '' ? CREATE_NOK + response.data.message : UPDATE_NOK + response.data.message);
            }

        } catch (error) {
            
            message.error(CREATE_NOK + error.message);
        }
        setLoadingForm(false);
    };

    const deleteProduct = async () => {
        try {
            const response = await productDel(idProduct);
            if (response.data.code === 0) {


                if (response.data.result.code === 0) {
                    message.success(DELETE_OK);
                    closeModal1(false);
                    fetchData();
                } else {
                    message.error(DELETE_NOK + response.data.result.message);
                }

            } else {
                message.error(DELETE_NOK + response.data.message);
            }
        } catch (error) {
            message.error(DELETE_NOK + error.message);
        }

    };
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const customRequest = ({ file, onSuccess }) => {
        const formData = new FormData();
        formData.append('files', file);

        // Realizar la solicitud de carga de archivos al servidor
        fetch('https://irest.cl/img-mercu/upload/uploadfile.php', {
            method: 'POST',
            body: formData,
        })
            .then(response => response.text())
            .then(data => {
                
                const regex = /\[(.*?)\]/;
                const match = data.match(regex);
                if (match && match.length > 1) {
                    message.success(data); // Mostrar el mensaje de éxito recibido del servidor
                    onSuccess(); // Informar al componente Upload que la carga se ha completado con éxito
                    const textoDentroCorchetes = match[1];
                    setImagenp(textoDentroCorchetes);
                } else {
                    message.error(data);
                }
            })
            .catch(error => {
                
                message.error('Error al subir el archivo.');
            });
    };
    return (<>
        <Modal
            title={titleModal}
            open={openModal}
            onCancel={closeModal1}
            maskClosable={false}
            footer={false}
        >
            <Spin spinning={loadingForm}>
                <Form

                    form={formProduct}
                    name="productForm"
                    labelCol={{
                        flex: '80px',
                    }}
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{
                        flex: 1,
                    }}
                    colon={false}
                    size="small"
                    onFinish={handleFinish}
                    autoComplete="off"
                    style={{
                        maxWidth: 600,
                        fontSize: 8
                    }}
                >
                    <br></br>
                    <Form.Item
                        label="Id"
                        name="id"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Local"
                        name="local"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="activo"
                        label="Publicar"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingresar',
                            },
                        ]}
                        style={{ marginBottom: '6px' }}
                    >
                        <Select placeholder="Publicar Si o No">
                            <Option value={1}>Si</Option>
                            <Option value={0}>No</Option>
                        </Select>
                    </Form.Item>


                    <Form.Item
                        name="categoria"
                        label="Categoria"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingresar',
                            },
                        ]}
                        style={{ marginBottom: '6px' }}
                    >

                        <Select placeholder="Seleccione Categoria">
                            {categories?.data?.results.map(option => (
                                <Option key={option.id} value={option.id}>{option.nombre}</Option>
                            ))}
                        </Select>

                    </Form.Item>

                    <Form.Item
                        label={<span><ProductOutlined style={{ color: "#0000ff" }} /> Titulo</span>}
                        name="titulo"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingresar',
                            },
                        ]}
                        style={{ marginBottom: '6px' }} // Ajusta el margen inferior
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="SubTitulo"
                        name="subtitulo"
                        style={{ marginBottom: '6px' }} // Ajusta el margen inferior
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Costo"
                        name="costo"
                        style={{ marginBottom: '6px' }} // Ajusta el margen inferior
                    >
                        <InputNumber addonBefore="$" min={0} disabled />
                    </Form.Item>
                    <Form.Item
                        label="Descuento"
                        name="descuento"
                        style={{ marginBottom: '6px' }} // Ajusta el margen inferior
                    >
                        <InputNumber addonAfter="%" min={0} max={99} />
                    </Form.Item>
                    <Form.Item
                        label="Venta"
                        name="venta"
                        style={{ marginBottom: '6px' }} // Ajusta el margen inferior
                    >
                        <InputNumber addonBefore="$" min={0} />
                    </Form.Item>
                    <Form.Item
                        label="Foto"
                        name="foto"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="laimagen"
                        label="Imagen"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        extra="Formato *.jpg max 150Kb"
                        style={{ marginBottom: '6px' }}
                    >
                        <Upload name="files" customRequest={customRequest} listType="picture" maxCount={1} >
                            <Button icon={<UploadOutlined />} size="small">Click para Cargar</Button>
                        </Upload>
                    </Form.Item>
                    {datosForm.foto ? (<Form.Item
                        label="Actual"
                        name="actual"
                    >
                        <Image
                            width={80}
                            height={80}
                            src={`https://irest.cl/img-mercu/${datosForm.foto}`}
                            style={{ padding: '4px 0' }}
                            preview={{
                                mask: (
                                    <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', padding: 10 }}>
                                        <EyeOutlined />
                                    </div>
                                ),
                            }}
                        />
                    </Form.Item>) : null}
                    <Form.Item
                        label="Stock"
                        name="orden"
                        style={{ marginBottom: '6px' }} // Ajusta el margen inferior
                    >
                        <InputNumber min={1} />
                    </Form.Item>
                    <Form.Item
                        label="Inventariar"
                        name="inventario"
                        style={{ marginBottom: '6px' }} // Ajusta el margen inferior
                    >
                       <Select placeholder="Inventario SI o NO">
                            <Option value="NO">NO</Option>
                            <Option value="SI">SI</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Describir"
                        name="descripcion"
                        style={{ marginBottom: '20px' }} // Ajusta el margen inferior
                    >
                        <Input.TextArea />
                    </Form.Item>

                    <Form.Item label=" ">
                        <div style={{ paddingBottom: 5, float: 'right' }}>
                            <Space align="end">
                                {datosForm.id ? <Popconfirm
                                    title="Advertencia"
                                    description={<span>¿Deseas eliminar el producto, no podras recuperalo {datosForm.titulo}?</span>}
                                    okText="Si, Eliminar"
                                    cancelText="Cancelar"
                                    onConfirm={deleteProduct}
                                >
                                    <Button danger><DeleteOutlined /></Button>
                                </Popconfirm> : null}
                                <Button type="dashed" onClick={closeModal1}>
                                    Cancel
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    Enviar
                                </Button>

                            </Space>
                        </div>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>

    </>);
};
export default ProductForm;