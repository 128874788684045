import { Router, Route, Switch } from 'wouter';
import Login from './pages/login/Login';
import Landing from './pages/layout/Landing';
import NotFound from './pages/NotFound';

function App() {
  return (

    <Router>
      <Switch>
        <Route path="/">
          <Login />
        </Route>
       
        <Route path="/landing">
          <Landing />
        </Route>
        <Route path="*">
                <NotFound />
            </Route>
      </Switch>
    </Router>
  );
}

export default App;
