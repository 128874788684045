import React from "react";
import { Tabs } from "antd";
import {TruckOutlined } from "@ant-design/icons";
import ProveedorTable from "./ProveedorTable";


const index = () => {
    const itemsTab = [
        {
            key: 1,
            label: 'Nuestros Proveedores',
            children: <ProveedorTable />,
            icon: <TruckOutlined style={{color:"#0e3789"}} />,
        },
    ];
    return (<Tabs
        defaultActiveKey="1"
        size='small'
        style={{
            marginBottom: 24,

        }}
        items={itemsTab}
    />);
}
export default index;