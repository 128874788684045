/* eslint-disable react-hooks/exhaustive-deps */
import { Popconfirm, Table, message } from "antd";
import { formatNumber } from "../../util";
import { useEffect } from "react";
import { gastoDetalleDel, getGastoDetalle } from "../../data/api";
import { DELETE_NOK, DELETE_OK } from "../../constans";

const GastoDetailsTable = ({idGasto, loadTable, gdTable, setLoadTable, setGdTable, fetchData}) => {
   // const [loadTable, setLoadTable] = useState(false);
   // const[gdTable,setGdTable] = useState([]);
    useEffect(()=>{
       // console.log('useEffect GastoDetailsTable');
        fetchGD();
    },[]);

    const handleDeleteGD = async(id) => {
        try {
            const response = await gastoDetalleDel(id,idGasto);
            if (response.data.code === 0) {
                if (response.data.result.code === 0) {

                    if (response.data.result.code === 0) {
                        message.success(DELETE_OK);
                        fetchGD();
                        fetchData();
                    } else {
                        message.error(DELETE_NOK + response.data.result.message);
                    }
                } else {
                    message.error(DELETE_NOK + response.data.result.message);
                }
            } else {
                message.error(DELETE_NOK + response.data.message);
            }
        } catch (error) {
            message.error(DELETE_NOK + error.message);
        }
    }
     
    const columns = [
   
        {
            title: '#',
            dataIndex: 'cantidad',
            key: 'cantidad',
            mobile: true,
            width:40
            
        },
        {
            title: 'Concepto',
            dataIndex: 'concepto',
            key: 'concepto',
            mobile: true,
            ellipsis: true,
            
            
            
        },
        {
            title: 'Costo',
            dataIndex: 'costo',
            key: 'costo',
            mobile: true,
            width:80,
            render: (text, record) => (
                <Popconfirm title="Deseas eliminar?" onConfirm={() => handleDeleteGD(record.id)}>
                <span>{formatNumber(record.costo)}</span>
                </Popconfirm>
              )
        },
       
      ];

      const fetchGD = async () => {
        setLoadTable(true);
        try{
          const response = await getGastoDetalle(idGasto);
         // console.log(response); 
          if (response.data.code === 0 && response.data.counts > 0) {
          
            const total = response.data.results.reduce((acc, curr) => acc + curr.costo, 0);
            const addTotal = [...response.data.results, { id: '',key:-1, cantidad:'==>',concepto: 'Total', costo: total }];
            setGdTable(addTotal);
        } else {
           setGdTable([]);
        }
        }catch(error){
          message.error(error.message);
        }
        setLoadTable(false);
    
      }



    return (
        <>
        <Table 
      dataSource={gdTable}
      columns={columns}
      bordered
      size="small"
      className="custom-table"
      loading={loadTable}
      pagination={false}
      rowClassName={(record) => record.concepto === 'Total' ? 'custom-bold-row' : ''}
      />
        </>
    );
};
export default GastoDetailsTable;