const initialState = {
    data: null,
    responsive: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_DATA_USER':
            return {
                ...state,
                data: action.payload,
            };
        case 'SET_DATA_TOKEN':
            return {
                ...state,
                data: action.payload,
                responsive: action.responsive
            };
        default:
            return state;
    }
};

export default reducer;