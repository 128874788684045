import React from "react";
import { Tabs } from "antd";
import {FallOutlined, UnorderedListOutlined } from "@ant-design/icons";
import GastoTable from "./GastoTable";
import GastoList from "./GastoList";




const index = () => {
    const itemsTab = [
        {
            key: 1,
            label: 'Gastos',
            children: <GastoTable />,
            icon: <FallOutlined style={{color:"#ff0000"}}/>,
        },
        {
            key: 2,
            label: 'Detalle',
            children: <GastoList />,
            icon: <UnorderedListOutlined style={{color:"#048025"}}/>,
        },
        
    ];
    return (<Tabs
        defaultActiveKey="1"
        size='small'
        style={{
            marginBottom: 24,

        }}
        items={itemsTab}
        
    />);
}
export default index;