/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, message } from "antd";
import RegularizaCostoControl from "./RegularizaCostoControl";
import RegularizaCostoTable from "./RegularizaCostoTable";
import { useEffect, useState } from "react";
import { formatFecha } from "../../util";
import dayjs from 'dayjs';
import { getRegularizaCosto } from "../../data/api";
import { useSelector } from "react-redux";

const RegularizaGrid = () =>{
    const[begin,setBegin]=useState('');
    const[end,setEnd]=useState('');
    const[descripcion, setDescripcion] = useState('');
    const[loading, setLoading] = useState(false);
    const[regularizaData, setRegularizaData] = useState([]);
    const dataToken = useSelector(state => state.data.data);

    useEffect(()=>{

        fecthProducts();

    },[]);

    const mostrar = (a,b)=>{
        console.log(a);
        console.log(b);

        setBegin(a);
        setEnd(b);
  
    if(a===null){
        message.error('Por favor ingrese la fecha');
        return;
    }
    fecthProducts(a, b);
        setDescripcion(formatFecha(a, b));


    }
    const fecthProducts = async (a,b) => {
        if (typeof a === 'undefined' && begin === '') {
            a = dayjs().startOf('month').format('YYYY-MM-DD');
            b = dayjs().endOf('month').format('YYYY-MM-DD');
          } else {
            if (begin !== '' && a === begin) {
              a = begin;
              b = end;
            }
            if (typeof a === 'undefined'){
              a = begin;
              b = end;
            }
          }
          
          setDescripcion(formatFecha(a, b));
          setLoading(true);
          try {
            const response = await getRegularizaCosto(dataToken.local, a, b);
            if (response.data.code === 0 && response.data.counts > 0) {
              setRegularizaData(response.data.results);
            } else {
                setRegularizaData([]);
            }
          } catch (error) {
            message.error(error.message);
          }
          setLoading(false);
    }
    return (
        <div style={{ padding: '0px' }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={8} lg={8}>
              <div style={{  padding: '8px 0', textAlign: 'center' }}><RegularizaCostoControl mostrar={mostrar}/></div>
            </Col>
            <Col xs={24} sm={16} lg={16}>
              <div style={{ padding: '8px 0', textAlign: 'center' }}><RegularizaCostoTable regularizaData={regularizaData} descripcion={descripcion} loading={loading} fecthProducts={fecthProducts} /></div>
            </Col>
          </Row>
        </div>
      );
};

export default RegularizaGrid;