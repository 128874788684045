/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Form, Image, Input, InputNumber, Modal, Popconfirm, Select, Space, Spin, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import { getPedidoProductos, getProductsActivos, pedidoDel, pedidoDetalleDel, pedidoPut,  pedprodupdatePost } from "../../data/api";
import { DELETE_NOK, DELETE_OK, UPDATE_NOK, UPDATE_OK } from "../../constans";
import { CloseOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";

import { formatNumber } from "../../util";
import { useSelector } from "react-redux";
const { Option } = Select;

const PedidoForm = ({ openModal, closeModal, datosForm, fetchData, clientes, tab }) => {
    const [loadingForm, setLoadingForm] = useState(false);
    const [idPedido, setIdPedido] = useState('');
    const[fotoreal,setFotoreal]=useState('notimage.svg');
    const [pedprodTable, setPedprodTable] = useState([]);
    const [formPedido] = Form.useForm();
    const [formPedidoAdd] = Form.useForm();
    const [openAddModal, setOpenAddModal] = useState(false);
    const [productosActivos, setProductActivos]=useState([]);
    const [productoId, setProductoId]=useState(0);
    const dataToken = useSelector(state => state.data.data);
    useEffect(() => {
        //console.log(dataToken);
        if (datosForm.id) {
            formPedido.setFieldsValue(datosForm);
            setIdPedido(datosForm.id);
            fetchPedProd(datosForm.id);
            fetchProductsActivos();
        }
    }, [formPedido, datosForm]);

    const closeAddModal=()=>{
        setOpenAddModal(false);
    }

    const columnsPedProd = [
        
        {
            title: 'Producto',
            dataIndex: 'nombreproducto',
            key: 'nombreproducto',
        },
        {
            title: 'Cant',
            dataIndex: 'cantidad',
            key: 'cantidad'
        },

        {
            title: 'DescF',
            dataIndex: 'descuentofinal',
            key: 'descuentofinal',
            render: (text, record) => (
                <span>{formatNumber(record.descuentofinal)}</span>
            )
        },
        {
            title: 'Unit',
            dataIndex: 'venta',
            key: 'venta',
            render: (text, record) => (
                <span>{formatNumber(record.venta)}</span>
            )
        },
        {
            title: 'VentaF',
            dataIndex: 'ventafinal',
            key: 'ventafinal',
            render: (text, record) => (
                <Popconfirm title="Deseas eliminar?" onConfirm={() => handleDeletePedidoDet(record.id,record.producto,record.cantidad,record.inventario)}>
                    <span>{formatNumber(record.ventafinal)}</span>
                </Popconfirm>
            ),
        }
    ];
    const handleDeletePedidoDet = async (id,producto,cantidad,inventario) => {
        try {
            const response = await pedidoDetalleDel(id, idPedido,producto,cantidad,inventario);
            if (response.data.code === 0) {
                if (response.data.result.code === 0) {
                    message.success(DELETE_OK);
                    //closeModal(false);
                    fetchData();
                    fetchPedProd(idPedido);
                    fetchProductsActivos();
                } else {
                    message.error(DELETE_NOK + response.data.result.message);
                }

            } else {
                message.error(DELETE_NOK + response.data.message);
            }
        } catch (error) {
            message.error(DELETE_NOK + error.message);
        }


    };
    const fetchPedProd = async (id) => {

        try {

            const response = await getPedidoProductos(id);

            if (response.data.code === 0 && response.data.counts > 0) {
                const totalPrecio = response.data.results.reduce((acc, item) => acc + item.ventafinal, 0);
                const totalCantidad = response.data.results.reduce((acc, item) => acc + item.cantidad, 0);
                const totalUnitario = response.data.results.reduce((acc, item) => acc + item.venta, 0);
                const totalDescuento = response.data.results.reduce((acc, item) => acc + item.descuentofinal, 0);
                const newData = [...response.data.results, { nombreproducto: 'Total', cantidad: totalCantidad, descuentofinal: totalDescuento, venta: totalUnitario, ventafinal: totalPrecio, key: -1 }];
                setPedprodTable(newData);
            } else {
                setPedprodTable([]);
            }

        } catch (error) {
            message.error(error.message);
        }

    };
    
    const fetchProductsActivos = async () =>{
        try {
            const response = await getProductsActivos(dataToken.local);
            if (response.data.code === 0 && response.data.counts > 0) {
                const formattedData = response.data.results.map((item, index) => ({
                    ...item,
                    value: item.id,
                    label: item.titulo +' (' +formatNumber(item.ventafinal)+')'
                }));
                setProductActivos(formattedData);
            }else{
                setProductActivos([]);
            }

        } catch (error) {
            message.error(error.message);
        }

    };

    const handleFinish = async (values) => {
        setLoadingForm(true);
        let adelanto = 0;
        let delivery = 0;
        let venta = 0;

        let pagado = '';
        for (const key in values) {
            if (values[key] === undefined) {
                values[key] = '';
            }
            if (key === 'parcial') {
                adelanto = values[key];
            }
            if (key === 'delivery') {
                delivery = values[key];
            }
            if (key === 'venta') {
                venta = values[key];
            }
            if (key === 'estado') {
                pagado = values[key];
            }



        }
        if (pagado === 'PAGADO') {
            values['porpagar'] = 0;
        } else {
            values['porpagar'] = venta + delivery - adelanto;
        }

        //calculo porpagar
        try {
            const response = await pedidoPut(values['id'], values);

            if (response.data.code === 0) {
                if (response.data.result.code === 0) {
                    message.success(UPDATE_OK);
                    closeModal(false);
                    fetchData();
                } else {
                    message.error(UPDATE_NOK + response.data.message);
                }
            } else {
                message.error(UPDATE_NOK + response.data.message);
            }

        } catch (error) {
            //console.log(error);
            message.error(UPDATE_NOK + error.message);
        }
        setLoadingForm(false);
    };

    const deletePedido = async () => {
       // console.log('Detalle',pedprodTable);
        /**Eliminar los productos y actualizar el stock */
        
        try {

            pedprodTable.forEach(async (dato) => {
                if (dato.inventario === 'SI'){
                   await handleDeletePedidoDet(dato.id,dato.producto,dato.cantidad,dato.inventario);
                }
            });


            const response = await pedidoDel(idPedido);
            if (response.data.code === 0) {
                if (response.data.result.code === 0) {
                    message.success(DELETE_OK);
                    closeModal(false);
                    fetchData();
                } else {
                    message.error(DELETE_NOK + response.data.result.message);
                }

            } else {
                message.error(DELETE_NOK + response.data.message);
            }
        } catch (error) {
            message.error(DELETE_NOK + error.message);
        }

    };

    const addPedido = () => {
        setOpenAddModal(true);
        formPedidoAdd.resetFields();
        setFotoreal('notimage.svg');
    }
    const handleFinishAdd = async (values) => {
        
        const productoFiltrado = productosActivos.find(item => item.id === productoId);
        
        values['pedido'] = idPedido;
        values['producto'] = productoId;
        values['costo'] = productoFiltrado.costo;
        values['venta'] = productoFiltrado.ventafinal;
        values['descuento']=productoFiltrado.descuentofinal;
        values['inventario']=productoFiltrado.inventario;
        values['stock'] =productoFiltrado.orden;
        //values['costo']=productoFiltrado;

        if(values['cantidad'] > productoFiltrado.orden && productoFiltrado.inventario==='SI'){
            message.error('No debe superar la cantidad DISPONIBLE...');
            return;
        }

        try{
        const responsex = await pedprodupdatePost(values);
                            
        if (responsex.data.code === 0) {
            if (responsex.data.result.code === 0) {
                closeAddModal();
                fetchPedProd(idPedido);
                fetchData();
                fetchProductsActivos();
                
            } else {
                message.error(responsex.data.message);
            }
        } else {
            message.error(responsex.data.message);
        }

      //  console.log(values);
    }catch(error){
        message.error(error.message);
    }
        
    }
    const onChangeSelect = (value) => {
       // console.log(value);
        setProductoId(value);

        const filtro = productosActivos.filter(item => item.id === value)[0];
       // console.log(filtro);
        formPedidoAdd.setFieldValue('stock',filtro.orden);
        formPedidoAdd.setFieldValue('ventaF',filtro.ventafinal);
        setFotoreal(filtro.foto);
        
        

    }

    return (<>
        <Modal
            title={`Pedido Nro ${formPedido.getFieldValue('id')}`}
            open={openModal}
            onCancel={closeModal}
            maskClosable={false}
            footer={false}
        >
            <Spin spinning={loadingForm}>
                <Form

                    form={formPedido}
                    name="pedidoForm"
                    labelCol={{
                        flex: '80px',
                    }}
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{
                        flex: 1,
                    }}
                    colon={false}
                    style={{
                        maxWidth: 600,
                        fontSize: 8
                    }}
                    size="small"
                    onFinish={handleFinish}
                    autoComplete="off"
                >
                    <br></br>
                    <Form.Item
                        label="Id"
                        name="id"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Local"
                        name="local"
                        hidden
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Entregado"
                        name="entregado"
                        style={{ marginBottom: '4px' }} // Ajusta el margen inferior
                    >
                        <Select>

                            <Option key={1} value="SI">SI</Option>
                            <Option key={2} value="NO">NO</Option>

                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Estado"
                        name="estado"
                        style={{ marginBottom: '4px' }} // Ajusta el margen inferior
                    >
                        <Select>
                            <Option key={1} value="DEBE">DEBE</Option>
                            <Option key={2} value="PAGADO">PAGADO</Option>
                            <Option key={3} value="ADELANTO">ADELANTO</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Modo"
                        name="modo"
                        style={{ marginBottom: '4px' }} // Ajusta el margen inferior
                    >
                        <Select>
                            <Option key={1} value="">N/A</Option>
                            <Option key={2} value="EFECTIVO">EFECTIVO</Option>
                            <Option key={3} value="TRANSFERENCIA">TRANSFERENCIA</Option>
                            <Option key={4} value="TARJETA">TARJETA</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="cliente"
                        label="Cliente"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingresar',
                            },
                        ]}
                        style={{ marginBottom: '6px' }}
                    >

                        <Select placeholder="Seleccione Cliente" showSearch>
                            {clientes?.data?.results.map(option => (
                                <Option key={option.id} value={option.id}>{option.nombres}</Option>
                            ))}
                        </Select>

                    </Form.Item>

                    <Form.Item
                        label="Fecha"
                        name="fecha"
                        style={{ marginBottom: '4px' }} // Ajusta el margen inferior
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Hora"
                        name="hora"
                        style={{ marginBottom: '4px' }} // Ajusta el margen inferior
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Costo"
                        name="costo"
                        style={{ marginBottom: '4px' }} // Ajusta el margen inferior
                        hidden
                    >
                        <Input readOnly />
                    </Form.Item>
                    <Form.Item
                        label="Adelanto"
                        name="parcial"
                        style={{ marginBottom: '4px' }} // Ajusta el margen inferior
                    >
                        <InputNumber style={{ width: 150 }} min={0} />
                    </Form.Item>
                    <Form.Item
                        label="Delivery"
                        name="delivery"
                        style={{ marginBottom: '4px' }} // Ajusta el margen inferior
                    >
                        <InputNumber style={{ width: 150 }} min={0} />
                    </Form.Item>
                    <Form.Item
                        label="Descuento"
                        name="descuento"
                        style={{ marginBottom: '4px' }} // Ajusta el margen inferior
                    >
                        <InputNumber style={{ width: 150 }} min={0} readOnly />
                    </Form.Item>
                    <Form.Item
                        label="Venta"
                        name="venta"
                        style={{ marginBottom: '4px' }} // Ajusta el margen inferior
                    >
                        <Input readOnly />
                    </Form.Item>
                    <Form.Item
                        label="PorPagar"
                        name="porpagar"
                        style={{ marginBottom: '4px' }} // Ajusta el margen inferior
                    >
                        <Input readOnly />
                    </Form.Item>

                    <Form.Item
                        label="OBS"
                        name="extra"
                        style={{ marginBottom: '10px' }} // Ajusta el margen inferior
                    >
                        <Input.TextArea />
                    </Form.Item>


                    {dataToken?.rol !=='normal' || tab!=='pagado' ?<Form.Item label=" ">
                        <div style={{ paddingBottom: 5, float: 'right' }}>
                            <Space align="end">
                                {datosForm.id ? <Popconfirm
                                    title="Advertencia"
                                    description={<span>¿Deseas eliminar el pedido {datosForm.nombres}?</span>}
                                    okText="Si, Eliminar"
                                    cancelText="Cancelar"
                                    onConfirm={deletePedido}
                                >
                                    <Button danger><DeleteOutlined /></Button>
                                </Popconfirm> : null}
                                <Button type="dashed" onClick={closeModal}>
                                    Cancel
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    Enviar
                                </Button>

                            </Space>
                        </div>

                    </Form.Item>:null}
                </Form>

                <Space style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ color: "#42282c", fontSize: 13 }}><strong>Detalle Pedido</strong></span>
                    {dataToken?.rol !=='normal' || tab!=='pagado' ?<Space>
                        <Button type="link" size="small" onClick={addPedido} icon={<PlusOutlined style={{ color: "#0e3789" }} />} />
                        <Button type="link" size="small" onClick={closeModal} icon={<CloseOutlined style={{ color: "#ff0000" }} />} />
                    </Space>:null}
                </Space>
                <Table dataSource={pedprodTable} columns={columnsPedProd} bordered size="small"
                    className="custom-table" pagination={false}
                    rowClassName={(record) => record.nombreproducto === 'Total' ? 'custom-bold-row' : ''}

                />
            </Spin>
        </Modal>

        <Modal
            title={`Agregar Producto - Pedido Nro ${formPedido.getFieldValue('id')}`}
            open={openAddModal}
            onCancel={closeAddModal}
            maskClosable={false}
            footer={false}
        >
            <Spin spinning={loadingForm}>
                <Form

                    form={formPedidoAdd}
                    name="pedidoAddForm"
                    labelCol={{
                        flex: '80px',
                    }}
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{
                        flex: 1,
                    }}
                    colon={false}
                    style={{
                        maxWidth: 600,
                        fontSize: 8
                    }}
                    size="small"
                    onFinish={handleFinishAdd}
                    autoComplete="off"
                >
                    <br></br>
                    <Form.Item
                        label="Producto"
                        name="producto"
                        style={{ marginBottom: '4px' }}
                        
                    >
                       <Select
                                    size="small"
                                    showSearch
                                    style={{
                                        width: 300,

                                    }}
                                    placeholder="Seleccionar..."
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={productosActivos}
                                    onChange={onChangeSelect}
                                    value={productosActivos?.label}
                                    
                                   
                                />
                    </Form.Item>
                    <Form.Item
                        label="Foto"
                        name="fotoreal"
                        style={{ marginBottom: '10px' }}
                        
                    >
                        <Image width={120}  height={80} src={`https://irest.cl/img-mercu/${fotoreal}`} />
                    </Form.Item>
                    <Form.Item
                        label="Disponible"
                        name="stock"
                        style={{ marginBottom: '10px' }}
                        
                    >
                        <InputNumber min={1} disabled/>
                    </Form.Item>
                    <Form.Item
                        label="VentaF"
                        name="ventaF"
                        style={{ marginBottom: '10px' }}
                        
                    >
                        <InputNumber min={1} disabled/>
                    </Form.Item>
                    <Form.Item
                        label="Cantidad"
                        name="cantidad"
                        style={{ marginBottom: '10px' }}
                        
                    >
                        <InputNumber min={1} />
                    </Form.Item>

                    <Form.Item label=" ">
                        <div style={{ paddingBottom: 5, float: 'right' }}>
                            <Space align="end">

                                <Button type="dashed" onClick={closeAddModal}>
                                    Cancel
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    Agregar
                                </Button>

                            </Space>
                        </div>

                    </Form.Item>

                </Form>
            </Spin>
        </Modal>

    </>);
};
export default PedidoForm;