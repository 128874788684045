import { Button, Popconfirm, Space, Table } from "antd";
import VentaFind from "./VentaFind";
import { useSelector } from "react-redux";
import { formatNumber } from "../../util";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { DownloadOutlined } from "@ant-design/icons";

const VentaResumen = ({mostrar, descripcion, resumenTable, loading}) => {
    const valResponsive = useSelector(state => state.responsive);
    //const dataToken = useSelector(state => state.data.data);

    const columns = [
        {
            title: 'Nro',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id,
            defaultSortOrder: 'descend',
            mobile: false,
            width: 60
        },
        {
            title: 'Cliente',
            dataIndex: 'cliente',
            key: 'cliente',
            mobile: true,
            width:100
        },
        {
            title: 'Fecha',
            dataIndex: 'fecha',
            key: 'fecha',
            mobile: true,
            width: 80
           
              
        },
        {
            title: 'CostoPre',
            dataIndex: 'costo',
            key: 'costo',
            mobile: false,
            render: (text, record) => (
                <span>{formatNumber(record.costo)}</span>
              ),
              width: 60
            
        },
        {
            title: 'Parcial',
            dataIndex: 'parcial',
            key: 'parcial',
            mobile: false,
            render: (text, record) => (
                <span>{formatNumber(record.parcial)}</span>
              ),
              width: 60
        },
        {
            title: 'Delivery',
            dataIndex: 'delivery',
            key: 'delivery',
            mobile: false,
            render: (text, record) => (
                <span>{formatNumber(record.delivery)}</span>
              ),
              width: 60
        },
        {
            title: 'Descue',
            dataIndex: 'descuento',
            key: 'descuento',
            mobile: false,
            render: (text, record) => (
                <span>{formatNumber(record.descuento)}</span>
              ),
              width: 60
        },
        {
            title: 'VentaPre',
            dataIndex: 'venta',
            key: 'venta',
            mobile: true,
            render: (text, record) => (
                 <span>{formatNumber(record.venta)}</span>
              ),
              width: 60
        },
        {
            title: 'Cant',
            dataIndex: 'totalcantidad',
            key: 'totalcantidad',
            mobile: true,
            render: (text, record) => (
                <span >{record.totalcantidad}</span>
              ),
            width: 60
        },
        {
            title: 'Costo',
            dataIndex: 'totalcosto',
            key: 'totalcosto',
            mobile: true,
            render: (text, record) => (
                <span style={{color:"#ff0000"}}>{formatNumber(record.costo)}</span>
              ),
              width: 60
        },
        {
            title: 'Desc',
            dataIndex: 'totaldescuento',
            key: 'totaldescuento',
            mobile: true,
            render: (text, record) => (
                <span style={{color:"#fe42c7"}}>{formatNumber(record.descuento)}</span>
              ),
              width: 60
        },
        {
            title: 'Venta',
            dataIndex: 'totalventa',
            key: 'totalventa',
            mobile: true,
            render: (text, record) => (
                <span style={{color:"#2e42c7"}}>{formatNumber(record.venta)}</span>
              ),
              width: 60
        },
       
        {
            title: 'Modo',
            dataIndex: 'modo',
            key: 'modo',
            mobile: false,
            width:120,
            ellipsis:true
            
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
            mobile: false,
            width:120,
            ellipsis:true
       
            
        },
        {
            title: 'Obs',
            dataIndex: 'extra',
            key: 'extra',
            mobile: false,
            ellipsis:true,
            width:100
            
        },
        
        
    ];
    const filteredMenuItems = columns.filter(item => {
        if (valResponsive) {
            if (item.mobile) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    
    });

    const downLoadExcel = () => {


        const fileType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(resumenTable);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const dataExcel = new Blob([excelBuffer], { type: fileType });
        saveAs(dataExcel, 'ResumenVentas' + fileExtension);

    };
    
    return (
<>
 <div style={{ paddingBottom: 3}}>
        <Space style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <VentaFind mostrar={mostrar}/>
        <Space>
        <span style={{fontSize:12}}>{descripcion}</span>
                    <Popconfirm
                        title="Advertencia"
                        description={<span>¿Estas seguro que deseas descargar la Excel?</span>}
                        okText="Si, Descargar"
                        cancelText="Cancelar"
                        onConfirm={downLoadExcel}
                    >
                        <Button type="link" size="small" icon={<DownloadOutlined style={{ color: "#0e3789" }} />} />
                    </Popconfirm>
                </Space>
        </Space>
        </div>

<Table dataSource={resumenTable}
columns={filteredMenuItems}
size="small"
className="custom-table"
loading={loading}
pagination={false}
rowClassName={(record) => record.fecha === 'Total' ? 'custom-bold-row' : ''}
/>
</>

    );
};
export default VentaResumen;