import { LineChartOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Tabs, message } from 'antd';
import React, { useState } from 'react';
import Estadistica from './Estadistica';
import VentaResumen from './VentasResumen';
import { formatFecha } from '../../util';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { getResumenVentas } from '../../data/api';


function Ventas() {
  const[resumenTable, setResumenTable] = useState([]);
  const[loading, setLoading] = useState(false);
  const[descripcion,setDescripcion] = useState('');
  const[begin, setBegin]=useState('');
  const[end, setEnd]=useState('');
  const dataToken = useSelector(state => state.data.data);


   

      const mostrar = (a, b) => {
        setBegin(a);
        setEnd(b);
  
    if(a===null){
        message.error('Por favor ingrese la fecha');
        return;
    }
        fetchResumenTable(a, b);
        setDescripcion(formatFecha(a, b));
    
      }
      const fetchResumenTable = async (a, b) => {
       
        if (typeof a === 'undefined' && begin === '') {
          a = dayjs().startOf('month').format('YYYY-MM-DD');
          b = dayjs().endOf('month').format('YYYY-MM-DD');
        } else {
          if (begin !== '' && a === begin) {
            a = begin;
            b = end;
          }
          if (typeof a === 'undefined'){
            a = begin;
            b = end;
          }
        }
        
        setDescripcion(formatFecha(a, b));
    
        setLoading(true);
        try {
          

          const response = await getResumenVentas(dataToken.local, a, b);
        
    
          if (response.data.code === 0 && response.data.counts > 0) {
            const costo = response.data.results.reduce((acc, curr) => acc + curr.costo, 0);
            const parcial = response.data.results.reduce((acc, curr) => acc + curr.parcial, 0);
            const delivery = response.data.results.reduce((acc, curr) => acc + curr.delivery, 0);
            const descuento = response.data.results.reduce((acc, curr) => acc + curr.descuento, 0);
            const venta = response.data.results.reduce((acc, curr) => acc + curr.venta, 0);
            const totalcosto = response.data.results.reduce((acc, curr) => acc + curr.totalcosto, 0);
            const totalcantidad = response.data.results.reduce((acc, curr) => acc + curr.totalcantidad, 0);
            const totalventa = response.data.results.reduce((acc, curr) => acc + curr.totalventa, 0);
            const totaldescuento = response.data.results.reduce((acc, curr) => acc + curr.totaldescuento, 0);

            const addTotal = [...response.data.results, { id: '#',key:-1, cliente:'',fecha: 'Total', costo: costo, parcial:parcial,delivery:delivery,descuento:descuento,venta:venta,estado:'',modo:'',totalcantidad:totalcantidad,totalcosto:totalcosto,totaldescuento:totaldescuento,totalventa:totalventa }];


            setResumenTable(addTotal);
          } else {
            setResumenTable([]);
          }
    
        } catch (error) {
          message.error(error.message);
        }
        setLoading(false);
    
      }
      const handleChangeTab = (activeKey) => {
      
        if(activeKey===2){
            fetchResumenTable();
        }
        
        
      };


      const itemsTab = [
        {
            key: 1,
            label: 'Ventas',
            children: <Estadistica  />,
            icon: <LineChartOutlined style={{color:"#0e3789"}} />,
        },
        {
          key: 2,
          label: 'Resumen',
          children: <VentaResumen mostrar={mostrar} descripcion={descripcion} resumenTable={resumenTable} loading={loading} />,
          icon: <UnorderedListOutlined style={{color:"#0e3789"}} />,
      },
        
       
    ];
  return (
    <div>
      <Tabs
        defaultActiveKey="1"
        size='small'
        style={{
            marginBottom: 24,

        }}
        items={itemsTab}
        onChange={handleChangeTab}
    />
    </div>
  );
};

export default Ventas;

