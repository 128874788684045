/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Form, Input, InputNumber, Modal, Popconfirm, Space, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { insumoDel, insumoPost, insumoPut } from "../../data/api";
import { CREATE_NOK, CREATE_OK, DELETE_NOK, DELETE_OK, UPDATE_NOK, UPDATE_OK } from "../../constans";
import { DeleteOutlined } from "@ant-design/icons";

const ProductInsumoForm = ({ openModal, closeModal, titleModal, datosForm, fetchData }) => {
    const [loadingForm, setLoadingForm] = useState(false);
    const [idInsumo, setIdInsumo] = useState('');

    const [formProductInsumo] = Form.useForm();

    useEffect(() => {
       // console.log('useEffect InsumoForm', datosForm);
        formProductInsumo.resetFields();
        formProductInsumo.setFieldsValue({ producto: datosForm.producto });
        if (datosForm.id) {
            formProductInsumo.setFieldsValue(datosForm);
            setIdInsumo(datosForm.id);
        } else {
            setIdInsumo('');
        }
    }, [formProductInsumo, datosForm]);



    const handleFinish = async (values) => {
        setLoadingForm(true);
        for (const key in values) {
            if (values[key] === undefined) {
                values[key] = '';
            }
            if (key === 'costo' && values[key] === '') {
                values[key] = '0';
            }
            if (key === 'orden' && values[key] === '') {
                values[key] = '1';
            }

        }

      //  console.log('Enviando', values);


        try {
            let response = [];


            values['id'] === '' ? response = await insumoPost(values) :

                response = await insumoPut(values['id'], values);



            if (response.data.code === 0) {
                if (response.data.result.code === 0) {
                    message.success(values['id'] === '' ? CREATE_OK : UPDATE_OK);
                    closeModal(false);
                    fetchData();
                } else {
                    message.error(values['id'] === '' ? CREATE_NOK + response.data.message : UPDATE_NOK + response.data.message);
                }
            } else {
                message.error(values['id'] === '' ? CREATE_NOK + response.data.message : UPDATE_NOK + response.data.message);
            }

        } catch (error) {
           // console.log(error);
            message.error(CREATE_NOK + error.message);
        }
        setLoadingForm(false);
    };

    const deleteInsumo = async () => {
        try {
            const response = await insumoDel(idInsumo, datosForm.producto);
            if (response.data.code === 0) {

                if (response.data.result.code === 0) {
                    message.success(DELETE_OK);
                    closeModal(false);
                    fetchData();
                } else {
                    message.error(DELETE_NOK + response.data.result.message);
                }

            } else {
                message.error(DELETE_NOK + response.data.message);
            }
        } catch (error) {
            message.error(DELETE_NOK + error.message);
        }

    };

    return (<>
        <Modal
            title={titleModal}
            open={openModal}
            onCancel={closeModal}
            maskClosable={false}
            footer={false}
        >
            <Spin spinning={loadingForm}>

                <Form

                    form={formProductInsumo}
                    name="productInsumoForm"
                    labelCol={{
                        flex: '80px',
                    }}
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{
                        flex: 1,
                    }}
                    colon={false}
                    size="small"
                    onFinish={handleFinish}
                    autoComplete="off"
                    style={{
                        maxWidth: 600,
                        fontSize: 8
                    }}
                >
                    <br></br>
                    <Form.Item
                        label="Id"
                        name="id"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Producto"
                        name="producto"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Insumo"
                        name="nombre"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingresar',
                            },
                        ]}
                        style={{ marginBottom: '6px' }} // Ajusta el margen inferior
                    >
                        <Input.TextArea />
                    </Form.Item>


                    <Form.Item
                        label="Costo"
                        name="costo"
                        style={{ marginBottom: '6px' }} // Ajusta el margen inferior
                    >
                        <InputNumber addonBefore="$" min={0} />
                    </Form.Item>




                    <Form.Item
                        label="Orden"
                        name="orden"
                        style={{ marginBottom: '15px' }} // Ajusta el margen inferior
                    >
                        <InputNumber min={1} />
                    </Form.Item>


                    <Form.Item label=" ">
                        <div style={{ paddingBottom: 5, float: 'right' }}>
                            <Space align="end">
                                {datosForm.id ? <Popconfirm
                                    title="Advertencia"
                                    description="¿Deseas eliminar el insumo?"
                                    okText="Si, Eliminar"
                                    cancelText="Cancelar"
                                    onConfirm={deleteInsumo}
                                >
                                    <Button danger><DeleteOutlined /></Button>
                                </Popconfirm> : null}
                                <Button type="dashed" onClick={closeModal}>
                                    Cancel
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    Enviar
                                </Button>

                            </Space>
                        </div>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>

    </>);
};
export default ProductInsumoForm;